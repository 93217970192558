import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { environment as env } from 'src/environments/environment';

/* LOBs */
import atv from '@lmig/safeco-ui/js/icon/lob/atv';
import auto from '@lmig/safeco-ui/js/icon/lob/auto';
import boat from '@lmig/safeco-ui/js/icon/lob/boat';
import classicCar from '@lmig/safeco-ui/js/icon/lob/classic-car';
import condo from '@lmig/safeco-ui/js/icon/lob/condo';
import earthquake from '@lmig/safeco-ui/js/icon/lob/earthquake';
import homeValuables from '@lmig/safeco-ui/js/icon/lob/home-valuables';
import homeWarranty from '@lmig/safeco-ui/js/icon/lob/home-warranty';
import home from '@lmig/safeco-ui/js/icon/lob/home';
import fire from '@lmig/safeco-ui/js/icon/lob/landlord';
import motorcycle from '@lmig/safeco-ui/js/icon/lob/motorcycle';
import pet from '@lmig/safeco-ui/js/icon/lob/pet';
import renters from '@lmig/safeco-ui/js/icon/lob/renters';
import trailer from '@lmig/safeco-ui/js/icon/lob/trailer';
import umbrella from '@lmig/safeco-ui/js/icon/lob/umbrella';

/* Products */
import bundle from '@lmig/safeco-ui/js/icon/products/bundle';
import autoProduct from '@lmig/safeco-ui/js/icon/products/auto';
import autoHome from '@lmig/safeco-ui/js/icon/products/auto-home';
import homeProduct from '@lmig/safeco-ui/js/icon/products/home';
import specialtyInsurance from '@lmig/safeco-ui/js/icon/products/specialty-insurance';

/* Auto */
import autoDamage from '@lmig/safeco-ui/js/icon/auto/auto-damage';
import autoId from '@lmig/safeco-ui/js/icon/auto/autoid';
import accidentForgiveness from '@lmig/safeco-ui/js/icon/auto/accident-forgiveness';
import glassDamage from '@lmig/safeco-ui/js/icon/auto/auto-glass-damage';
import multiCar from '@lmig/safeco-ui/js/icon/auto/multicar';
import newCarReplacement from '@lmig/safeco-ui/js/icon/auto/new-car-replacement';
import rentalCarReimbursement from '@lmig/safeco-ui/js/icon/auto/rental-car-reimbursement';
import rightTrack from '@lmig/safeco-ui/js/icon/auto/right-track';
import vehicleTheft from '@lmig/safeco-ui/js/icon/auto/vehicle-theft';

/* Home */
import homePhysicalDamage from '@lmig/safeco-ui/js/icon/home/home-physical-damage';

/* Coverages */
import barkingDog from '@lmig/safeco-ui/js/icon/coverage-options/barking-dog';
import bodilyInjury from '@lmig/safeco-ui/js/icon/coverage-options/bodily-injury';
import collision from '@lmig/safeco-ui/js/icon/coverage-options/collision';
import coverages from '@lmig/safeco-ui/js/icon/coverage-options/coverages';
import deductible from '@lmig/safeco-ui/js/icon/coverage-options/deductible';
import deductibleFund from '@lmig/safeco-ui/js/icon/coverage-options/deductible-fund';
import lossOfUse from '@lmig/safeco-ui/js/icon/coverage-options/lossof-use';
import medical from '@lmig/safeco-ui/js/icon/coverage-options/medical';
import medicalExpenses from '@lmig/safeco-ui/js/icon/coverage-options/medical-expenses';
import otherStructure from '@lmig/safeco-ui/js/icon/coverage-options/other-structure';
import personalProperty from '@lmig/safeco-ui/js/icon/coverage-options/personal-property';
import propertyDamageLiability from '@lmig/safeco-ui/js/icon/coverage-options/property-damage-liability';
import roadsideAssistance from '@lmig/safeco-ui/js/icon/coverage-options/24hr-roadside-assistance';
import roadsideAssistancePlus from '@lmig/safeco-ui/js/icon/coverage-options/24hr-roadside-assistance-plus';
import uninsured from '@lmig/safeco-ui/js/icon/coverage-options/uninsured';
import valuables from '@lmig/safeco-ui/js/icon/coverage-options/valuables';
import workersComp from '@lmig/safeco-ui/js/icon/coverage-options/workers-comp';

/* Account Experience */
import aboutMe from '@lmig/safeco-ui/js/icon/account-experience/about-me';
import attachments from '@lmig/safeco-ui/js/icon/account-experience/attachments';
import bank from '@lmig/safeco-ui/js/icon/account-experience/bank';
import billingPayment from '@lmig/safeco-ui/js/icon/account-experience/billing-payment';
import bookmark from '@lmig/safeco-ui/js/icon/account-experience/bookmark';
import broken from '@lmig/safeco-ui/js/icon/account-experience/broken';
import calculator from '@lmig/safeco-ui/js/icon/account-experience/calculator';
import calendarLrg from '@lmig/safeco-ui/js/icon/account-experience/calendar-lrg';
import calendarSm from '@lmig/safeco-ui/js/icon/account-experience/calendar-sm';
import camera from '@lmig/safeco-ui/js/icon/account-experience/camera';
import chat from '@lmig/safeco-ui/js/icon/account-experience/chat';
import check from '@lmig/safeco-ui/js/icon/account-experience/check';
import checkbook from '@lmig/safeco-ui/js/icon/account-experience/checkbook';
import checklist from '@lmig/safeco-ui/js/icon/account-experience/checklist';
import claims from '@lmig/safeco-ui/js/icon/account-experience/claims';
import clock from '@lmig/safeco-ui/js/icon/account-experience/clock';
import computerMouse from '@lmig/safeco-ui/js/icon/account-experience/computer-mouse';
import creditCard from '@lmig/safeco-ui/js/icon/account-experience/credit-card';
import customerBrochure from '@lmig/safeco-ui/js/icon/account-experience/customer-brochure';
import customerService from '@lmig/safeco-ui/js/icon/account-experience/customer-service';
import desktopSoftware from '@lmig/safeco-ui/js/icon/account-experience/desktop-software';
import desktop from '@lmig/safeco-ui/js/icon/account-experience/desktop';
import documentEft from '@lmig/safeco-ui/js/icon/account-experience/document-eft';
import documentExcel from '@lmig/safeco-ui/js/icon/account-experience/document-excel';
import documentLifetimeRepair from '@lmig/safeco-ui/js/icon/account-experience/document-lifetime-repair';
import documentMulti from '@lmig/safeco-ui/js/icon/account-experience/document-multi';
import documentPdf from '@lmig/safeco-ui/js/icon/account-experience/document-pdf';
import documentQuote from '@lmig/safeco-ui/js/icon/account-experience/document-quote';
import documentSecure from '@lmig/safeco-ui/js/icon/account-experience/document-secure';
import documentTimeSensitive from '@lmig/safeco-ui/js/icon/account-experience/document-time-sensitive';
import documentWord from '@lmig/safeco-ui/js/icon/account-experience/document-word';
import document from '@lmig/safeco-ui/js/icon/account-experience/document';
import duplicate from '@lmig/safeco-ui/js/icon/account-experience/duplicate';
import email from '@lmig/safeco-ui/js/icon/account-experience/email';
import envelopeMail from '@lmig/safeco-ui/js/icon/account-experience/envelope-mail';
import esign from '@lmig/safeco-ui/js/icon/account-experience/esign';
import express from '@lmig/safeco-ui/js/icon/account-experience/express';
import findAgent from '@lmig/safeco-ui/js/icon/account-experience/find-agent';
import folder from '@lmig/safeco-ui/js/icon/account-experience/folder';
// import fontSize from '@lmig/safeco-ui/js/icon/account-experience/font-size';
import fullscreen from '@lmig/safeco-ui/js/icon/account-experience/fullscreen';
import id from '@lmig/safeco-ui/js/icon/account-experience/id';
import laptop from '@lmig/safeco-ui/js/icon/account-experience/laptop';
import lightbulb from '@lmig/safeco-ui/js/icon/account-experience/lightbulb';
import lockOpen from '@lmig/safeco-ui/js/icon/account-experience/lock-open';
import multichannel from '@lmig/safeco-ui/js/icon/account-experience/multichannel';
import multidevice from '@lmig/safeco-ui/js/icon/account-experience/multidevice';
import newIcon from '@lmig/safeco-ui/js/icon/account-experience/new';
import notification from '@lmig/safeco-ui/js/icon/account-experience/notification';
import packageIcon from '@lmig/safeco-ui/js/icon/account-experience/package';
import paperless from '@lmig/safeco-ui/js/icon/account-experience/paperless';
import pen from '@lmig/safeco-ui/js/icon/account-experience/pen';
import phone from '@lmig/safeco-ui/js/icon/account-experience/phone';
import photoGallery from '@lmig/safeco-ui/js/icon/account-experience/photo-gallery';
import policy from '@lmig/safeco-ui/js/icon/account-experience/policy';
import powerOn from '@lmig/safeco-ui/js/icon/account-experience/power-on';
import priceTag from '@lmig/safeco-ui/js/icon/account-experience/price-tag';
import printer from '@lmig/safeco-ui/js/icon/account-experience/printer';
import professionalServices from '@lmig/safeco-ui/js/icon/account-experience/professional-services';
import proofOfInsurance from '@lmig/safeco-ui/js/icon/account-experience/proofof-insurance';
import questionCircle from '@lmig/safeco-ui/js/icon/account-experience/question-circle';
import questionMark from '@lmig/safeco-ui/js/icon/account-experience/question-mark';
import savingsDiscount from '@lmig/safeco-ui/js/icon/account-experience/savings-discount';
import share from '@lmig/safeco-ui/js/icon/account-experience/share';
import shoppingCart from '@lmig/safeco-ui/js/icon/account-experience/shopping-cart';
import signDoc from '@lmig/safeco-ui/js/icon/account-experience/sign-doc';
import smartphone from '@lmig/safeco-ui/js/icon/account-experience/smartphone';
import specialist from '@lmig/safeco-ui/js/icon/account-experience/specialist';
import star from '@lmig/safeco-ui/js/icon/account-experience/star';
import stopwatch from '@lmig/safeco-ui/js/icon/account-experience/stopwatch';
import tablet from '@lmig/safeco-ui/js/icon/account-experience/tablet';
import time from '@lmig/safeco-ui/js/icon/account-experience/time';
import video from '@lmig/safeco-ui/js/icon/account-experience/video';
import wifi from '@lmig/safeco-ui/js/icon/account-experience/wifi';

/* System */
import accordionCaretDown from '@lmig/safeco-ui/js/icon/system/accordion-caret-down';
import accordionCaretUp from '@lmig/safeco-ui/js/icon/system/accordion-caret-up';
import addItem from '@lmig/safeco-ui/js/icon/system/add-item';
import add from '@lmig/safeco-ui/js/icon/system/add';
import bell from '@lmig/safeco-ui/js/icon/system/bell';
import brokenLinkCircle from '@lmig/safeco-ui/js/icon/system/broken-link-circle';
import brokenLink from '@lmig/safeco-ui/js/icon/system/broken-link';
import caretCircleLeft from '@lmig/safeco-ui/js/icon/system/caret-circle-left';
import caretCircleRight from '@lmig/safeco-ui/js/icon/system/caret-circle-right';
import caretDown from '@lmig/safeco-ui/js/icon/system/caret-down';
import caretLeft from '@lmig/safeco-ui/js/icon/system/caret-left';
import caretRight from '@lmig/safeco-ui/js/icon/system/caret-right';
import caretUp from '@lmig/safeco-ui/js/icon/system/caret-up';
import checkmark from '@lmig/safeco-ui/js/icon/system/checkmark';
import closeCircle from '@lmig/safeco-ui/js/icon/system/close-circle';
import close from '@lmig/safeco-ui/js/icon/system/close';
import contact from '@lmig/safeco-ui/js/icon/system/contact';
import download from '@lmig/safeco-ui/js/icon/system/download';
import edit from '@lmig/safeco-ui/js/icon/system/edit';
import errorAlert from '@lmig/safeco-ui/js/icon/system/error-alert';
import help from '@lmig/safeco-ui/js/icon/system/help';
import info from '@lmig/safeco-ui/js/icon/system/info';
import linkCaretDown from '@lmig/safeco-ui/js/icon/system/link-caret-down';
import linkCaretLeft from '@lmig/safeco-ui/js/icon/system/link-caret-left';
import linkCaretRight from '@lmig/safeco-ui/js/icon/system/link-caret-right';
import listBullet from '@lmig/safeco-ui/js/icon/system/list-bullet';
import location from '@lmig/safeco-ui/js/icon/system/location';
import logIn from '@lmig/safeco-ui/js/icon/system/log-in';
import menu from '@lmig/safeco-ui/js/icon/system/menu';
import more from '@lmig/safeco-ui/js/icon/system/more';
import myAccount from '@lmig/safeco-ui/js/icon/system/my-account';
import offsite from '@lmig/safeco-ui/js/icon/system/offsite';
import search from '@lmig/safeco-ui/js/icon/system/search';
import successAlert from '@lmig/safeco-ui/js/icon/system/success-alert';
import textAlerts from '@lmig/safeco-ui/js/icon/system/text-alerts';
import thumbsDown from '@lmig/safeco-ui/js/icon/system/thumbs-down';
import thumbsUp from '@lmig/safeco-ui/js/icon/system/thumbs-up';
import trash from '@lmig/safeco-ui/js/icon/system/trash';
import upload from '@lmig/safeco-ui/js/icon/system/upload';
import warning from '@lmig/safeco-ui/js/icon/system/warning';

import { Color, LegacyColor } from 'src/app/utilities/constants/colorNames';

// From lmig design system. Not to be used yet.
class SVGProps {
  /**
   * Specifies the actual size of the inner svg icon.
   * @default '32'
   */
  size?: '16' | '24' | '32' | '48' | '64';
  /**
  * Similar to the "alt" attribute on image tags, this is optional for custom tooltips and will default to the icon name if not overriden.
  */
  title?: string;
  /**
  * Add an additional class name to the svg tag
  */
  className?: string;
}

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit, OnChanges {
  @Input() name: string = '';
  @Input() labelVisual?: string = '';
  @Input() labelA11y?: string = '';
  @Input() size?: '16' | '24' | '32' | '48' | '64';
  @Input() color?: LegacyColor | Color | '';
  @Input() circleColor?: 'yellow' | 'gray' | '';

  icon: any;
  imgLoc: string = env.imgLoc;

  constructor() { }

  ngOnInit() {
    this.icon = this.setIcon(this.name);
  }

  ngOnChanges() {
    this.icon = this.setIcon(this.name);
  }

  setIcon(iconName: string) {
    const lobIconProps: any = {
      size: this.size ? this.size : '32',
      circleColor: this.circleColor ? this.circleColor : 'gray',
      color: this.getLMDSColor(),
      labelVisual: this.labelVisual,
      labelA11y: this.labelA11y ? this.labelA11y : this.labelVisual
    };

    const iconProps: any = {
      size: this.size ? this.size : '16',
      circleColor: this.circleColor ? this.circleColor : null,
      color: this.getLMDSColor(),
      labelVisual: this.labelVisual,
      labelA11y: this.labelA11y ? this.labelA11y : this.labelVisual
    };

    switch (iconName) {
      case 'atv':
        return atv(lobIconProps);
      case 'auto':
      case 'recVehicle':
      case 'RecVehicle':
      case 'rv':
        return auto(lobIconProps);
      case 'boat':
        return boat(lobIconProps);
      case 'classicCar':
        return classicCar(lobIconProps);
      case 'condo':
        return condo(lobIconProps);
      case 'earthquake':
        return earthquake(lobIconProps);
      case 'homeValuables':
        return homeValuables(lobIconProps);
      case 'homeWarranty':
        return homeWarranty(lobIconProps);
      case 'home':
        return home(lobIconProps);
      case 'fire':
      case 'dwellingfire':
      case 'DwellingFire':
        return fire(lobIconProps);
      case 'motorcycle':
        return motorcycle(lobIconProps);
      case 'pet':
        return pet(lobIconProps);
      case 'renters':
        return renters(lobIconProps);
      case 'trailer':
        return trailer(lobIconProps);
      case 'umbrella':
        return umbrella(lobIconProps);

      case 'aboutMe':
        return aboutMe(iconProps);
      case 'attachments':
        return attachments(iconProps);
      case 'bank':
        return bank(iconProps);
      case 'billingPayment':
        return billingPayment(iconProps);
      case 'bookmark':
        return bookmark(iconProps);
      case 'broken':
        return broken(iconProps);
      case 'calculator':
        return calculator(iconProps);
      case 'calendarLrg':
        return calendarLrg(iconProps);
      case 'calendarSm':
        return calendarSm(iconProps);
      case 'camera':
        return camera(iconProps);
      case 'chat':
        return chat(iconProps);
      case 'check':
        return check(iconProps);
      case 'checkbook':
        return checkbook(iconProps);
      case 'checklist':
        return checklist(iconProps);
      case 'claims':
        return claims(iconProps);
      case 'clock':
        return clock(iconProps);
      case 'computerMouse':
        return computerMouse(iconProps);
      case 'creditCard':
        return creditCard(iconProps);
      case 'customerBrochure':
        return customerBrochure(iconProps);
      case 'customerService':
        return customerService(iconProps);
      case 'desktopSoftware':
        return desktopSoftware(iconProps);
      case 'desktop':
        return desktop(iconProps);
      case 'documentEft':
        return documentEft(iconProps);
      case 'documentExcel':
        return documentExcel(iconProps);
      case 'documentLifetimeRepair':
        return documentLifetimeRepair(iconProps);
      case 'documentMulti':
        return documentMulti(iconProps);
      case 'documentPdf':
        return documentPdf(iconProps);
      case 'documentQuote':
        return documentQuote(iconProps);
      case 'documentSecure':
        return documentSecure(iconProps);
      case 'documentTimeSensitive':
        return documentTimeSensitive(iconProps);
      case 'documentWord':
        return documentWord(iconProps);
      case 'document':
        return document(iconProps);
      case 'duplicate':
        return duplicate(iconProps);
      case 'email':
        return email(iconProps);
      case 'envelopeMail':
        return envelopeMail(iconProps);
      case 'esign':
        return esign(iconProps);
      case 'express':
        return express(iconProps);
      case 'findAgent':
        return findAgent(iconProps);
      case 'folder':
        return folder(iconProps);
      // case'fontSize':
      //   return fontSize(iconProps);
      case 'fullscreen':
        return fullscreen(iconProps);
      case 'id':
        return id(iconProps);
      case 'laptop':
        return laptop(iconProps);
      case 'lightbulb':
        return lightbulb(iconProps);
      case 'lockOpen':
        return lockOpen(iconProps);
      case 'multichannel':
        return multichannel(iconProps);
      case 'multidevice':
        return multidevice(iconProps);
      case 'new':
        return newIcon(iconProps);
      case 'notification':
        return notification(iconProps);
      case 'package':
        return packageIcon(iconProps);
      case 'paperless':
        return paperless(iconProps);
      case 'pen':
        return pen(iconProps);
      case 'phone':
        return phone(iconProps);
      case 'photoGallery':
        return photoGallery(iconProps);
      case 'policy':
        return policy(iconProps);
      case 'powerOn':
        return powerOn(iconProps);
      case 'priceTag':
        return priceTag(iconProps);
      case 'printer':
        return printer(iconProps);
      case 'professionalServices':
        return professionalServices(iconProps);
      case 'proofOfInsurance':
        return proofOfInsurance(iconProps);
      case 'questionCircle':
        return questionCircle(iconProps);
      case 'questionMark':
        return questionMark(iconProps);
      case 'savingsDiscount':
        return savingsDiscount(iconProps);
      case 'share':
        return share(iconProps);
      case 'shoppingCart':
        return shoppingCart(iconProps);
      case 'signDoc':
        return signDoc(iconProps);
      case 'smartphone':
        return smartphone(iconProps);
      case 'specialist':
        return specialist(iconProps);
      case 'star':
        return star(iconProps);
      case 'stopwatch':
        return stopwatch(iconProps);
      case 'tablet':
        return tablet(iconProps);
      case 'time':
        return time(iconProps);
      case 'video':
        return video(iconProps);
      case 'wifi':
        return wifi(iconProps);

      case 'accordionCaretDown':
        return accordionCaretDown(iconProps);
      case 'accordionCaretUp':
        return accordionCaretUp(iconProps);
      case 'addItem':
        return addItem(iconProps);
      case 'add':
        return add(iconProps);
      case 'bell':
        return bell(iconProps);
      case 'brokenLinkCircle':
        return brokenLinkCircle(iconProps);
      case 'brokenLink':
        return brokenLink(iconProps);
      case 'caretCircleLeft':
        return caretCircleLeft(iconProps);
      case 'caretCircleRight':
        return caretCircleRight(iconProps);
      case 'caretDown':
        return caretDown(iconProps);
      case 'caretLeft':
        return caretLeft(iconProps);
      case 'caretRight':
        return caretRight(iconProps);
      case 'caretUp':
        return caretUp(iconProps);
      case 'checkmark':
        return checkmark(iconProps);
      case 'closeCircle':
        return closeCircle(iconProps);
      case 'close':
        return close(iconProps);
      case 'contact':
        return contact(iconProps);
      case 'download':
        return download(iconProps);
      case 'edit':
        return edit(iconProps);
      case 'errorAlert':
        return errorAlert(iconProps);
      case 'help':
        return help(iconProps);
      case 'info':
        return info(iconProps);
      case 'linkCaretDown':
        return linkCaretDown(iconProps);
      case 'linkCaretLeft':
        return linkCaretLeft(iconProps);
      case 'linkCaretRight':
        return linkCaretRight(iconProps);
      case 'listBullet':
        return listBullet(iconProps);
      case 'location':
        return location(iconProps);
      case 'logIn':
        return logIn(iconProps);
      case 'menu':
        return menu(iconProps);
      case 'more':
        return more(iconProps);
      case 'myAccount':
        return myAccount(iconProps);
      case 'offsite':
        return offsite(iconProps);
      case 'search':
        return search(iconProps);
      case 'successAlert':
        return successAlert(iconProps);
      case 'textAlerts':
        return textAlerts(iconProps);
      case 'thumbsDown':
        return thumbsDown(iconProps);
      case 'thumbsUp':
        return thumbsUp(iconProps);
      case 'trash':
        return trash(iconProps);
      case 'upload':
        return upload(iconProps);
      case 'warning':
        return warning(iconProps);

      case 'autoHome':
        return autoHome(iconProps);
      case 'autoProduct':
        return autoProduct(iconProps);
      case 'bundle':
        return bundle(iconProps);
      case 'homeProduct':
        return homeProduct(iconProps);
      case 'specialtyInsurance':
        return specialtyInsurance(iconProps);

      case 'accidentForgiveness':
        return accidentForgiveness(iconProps);
      case 'barkingDog':
        return barkingDog(iconProps);
      case 'glassDamage':
        return glassDamage(iconProps);
      case 'newCarReplacement':
        return newCarReplacement(iconProps);
      case 'rentalCarReimbursement':
        return rentalCarReimbursement(iconProps);
      case 'bodilyInjury':
        return bodilyInjury(iconProps);
      case 'collision':
        return collision(iconProps);
      case 'deductible':
        return deductible(iconProps);
      case 'deductibleFund':
        return deductibleFund(iconProps);
      case 'autoDamage':
        return autoDamage(iconProps);
      case 'medical':
        return medical(iconProps);
      case 'medicalExpenses':
        return medicalExpenses(iconProps);
      case 'propertyDamageLiability':
        return propertyDamageLiability(iconProps);
      case 'roadsideAssistancePlus':
        return roadsideAssistancePlus(iconProps);
      case 'uninsured':
        return uninsured(iconProps);
      case 'workersComp':
        return workersComp(iconProps);
      case 'homePhysicalDamage':
        return homePhysicalDamage(iconProps);
      case 'lossOfUse':
        return lossOfUse(iconProps);
      case 'otherStructure':
        return otherStructure(iconProps);
      case 'personalProperty':
        return personalProperty(iconProps);
      case 'valuables':
        return valuables(iconProps);

      case 'autoId':
        return autoId(iconProps);
      case 'coverages':
        return coverages(iconProps);
      case 'multiCar':
        return multiCar(iconProps);
      case 'rightTrack':
        return rightTrack(iconProps);
      case 'roadsideAssistance':
        return roadsideAssistance(iconProps);
      case 'vehicleTheft':
        return vehicleTheft(iconProps);

      // temp svgs for CC brands
      case 'AX':
      case 'DI':
      case 'MC':
      case 'VI':
        return this.getCCImgElement(this.name);

      default:
        return '<span></span>';
    }  
  }

  getCCImgElement(cardBrand: string): string {
    let src = this.imgLoc + "/credit-cards/";
    let alt = "";

    if (cardBrand) {
      switch (cardBrand) {
        case 'AX':
          src += 'amex.svg';
          alt = 'American Express';
          break;
        case 'DI':
          src += 'discover.svg';
          alt = 'Discover';
          break;
        case 'MC':
          src += 'mastercard.svg';
          alt = 'MasterCard';
          break;
        case 'VI':
          src += 'visa.svg';
          alt = 'Visa';
          break;
        default:
          break;
      }
    }

    const imgElement = `<img src="${src}" alt="${alt}" role="img"/>`

    return imgElement;
  }
  
  getLMDSColor(): string {
    switch (this.color) {
      case LegacyColor.DARK:
      case "":
        return Color.DEFAULT;
      case LegacyColor.SUCCESS:
        return Color.POSITIVE;
      case LegacyColor.WARNING:
        return Color.CAUTION;
      case LegacyColor.ERROR:
        return Color.NEGATIVE;
      case LegacyColor.ACTIVE:
        return Color.TEAL;
      default:
        return this.color;
    }
  }
}

@Component({
  selector: 'app-cc-icon',
  templateUrl: './cc-icon.component.html',
  styleUrls: ['./cc-icon.component.scss']
})
export class CreditCardIconComponent implements OnInit {
  @Input() cardBrand: string = "";

  ngOnInit() {
  }
}

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent implements OnInit {
  @Input() svgImage: string = "";

  ngOnInit() {
  }
}
