import { Component, OnInit, Output, EventEmitter, Input, DoCheck } from '@angular/core';

import { EFTAccountTypes, EFTErrorCodes } from 'src/app/utilities/constants/billingPayment';
import { environment as env } from 'src/environments/environment';
import { FieldValidation, ValidationRegex } from 'src/app/utilities/models/fieldValidation';
import EFTInfo from 'src/app/utilities/models/eftInfo';
import { PaymentStorage } from 'src/app/utilities/models/billingPaymentAccount';
import CAMStorage from 'src/app/utilities/constants/CAMStorage';

import { ModalService } from 'src/app/utilities/services/modal-service/modal.service';
import { ModalNames } from 'src/app/utilities/constants/modalNames';

@Component({
  selector: 'app-eft-input',
  templateUrl: './eft-input.component.html',
  styleUrls: ['./eft-input.component.scss']
})
export class EftInputComponent implements OnInit, DoCheck {
  readonly accountTypeList: any = EFTAccountTypes;

  @Input() EFTData: EFTInfo;
  @Input() fieldErrorCode: number = null;
  @Input() overrideAction?: boolean;
  @Input() hideTextForMakePayment: boolean;
  @Input() displayForMakePayment: boolean;

  @Output() EFTDataChange: EventEmitter<EFTInfo> = new EventEmitter<EFTInfo>();
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() authorizeEvent: EventEmitter<any> = new EventEmitter<any>();

  imgLoc: string = env.imgLoc;
  originalEFTData: EFTInfo;
  shouldClearFieldsOnChange: boolean = true;
  paymentStorage: PaymentStorage;
  editAfterReviewForMakePayment: boolean = false;

  nameOnBankValidation: FieldValidation = new FieldValidation(true);
  routingNumberValidation: FieldValidation = new FieldValidation(true);
  accountNumberValidation: FieldValidation = new FieldValidation(true);

  constructor(private modalService: ModalService) { }

  ngOnInit() {
      this.getPaymentStorage();
      if (this.paymentStorage) {
          this.editAfterReviewForMakePayment = this.paymentStorage.pageEdit;
      }
      if (this.EFTData) {
      this.originalEFTData = JSON.parse(JSON.stringify(this.EFTData));
      this.setPrefilledEFTData();
      if (this.editAfterReviewForMakePayment) {
          this.shouldClearFieldsOnChange = false;
      }
    } else {
      this.EFTData = new EFTInfo();
      this.originalEFTData = new EFTInfo();
      this.shouldClearFieldsOnChange = false;
      this.clearEFTInfo();
    }

    this.setInputValidationRules();
  }

  ngDoCheck() {
    if (this.fieldErrorCode) {
      switch (this.fieldErrorCode) {
        case EFTErrorCodes.invalidBankAccountNumber:
          this.accountNumberValidation.forceError = true;
          this.accountNumberValidation.forceErrorMessage = 'Valid bank account number required — please try again';
          break;
        case EFTErrorCodes.invalidRoutingNumber:
          this.routingNumberValidation.forceError = true;
          this.routingNumberValidation.forceErrorMessage = 'Valid routing number required — please try again';
          break;
      }
    }
  }

  private setInputValidationRules(): void {
    const numberOnlyRule: any = { pattern: ValidationRegex.numericOnly };
    this.nameOnBankValidation.validations.push({pattern: ValidationRegex.alphaAndEmptyStringOnly, message: 'Name on account should contain only letters'});   
    this.routingNumberValidation.validations.push(numberOnlyRule);
    this.routingNumberValidation.validations.push({ pattern: ValidationRegex.maxLengthNumeric(9), message: 'Routing Number must be 9 digits' });
    this.accountNumberValidation.validations.push(numberOnlyRule);
    this.accountNumberValidation.originalValue = this.EFTData.accountNumber ? this.EFTData.accountNumber : null;
  }

  setPrefilledEFTData() {
    this.nameOnBankValidation.hasError = this.EFTData.nameOnBank ? false : true;
    this.routingNumberValidation.hasError = this.EFTData.routingNumber ? false : true;
    this.accountNumberValidation.hasError = this.EFTData.accountNumber ? false : true;
  }

  setEFTAccountType(accountType: string): void {
    this.EFTData.accountType = accountType;
  }

  openEFTAuthorizationModal(): void {
    this.modalService.openModal(ModalNames.EFT_AUTHORIZATION);
  }

  getPaymentStorage(): void {
    this.paymentStorage = CAMStorage.getItemInStorage(CAMStorage.storageKeys.billingPaymentStorage, true);
  }

  openRoutingAccountNumber() {
    this.modalService.openModal(ModalNames.ROUTING_ACCT_NUMBER);
  }

  onFieldChange(): void {
    this.fieldErrorCode = null;

    if (this.shouldClearFieldsOnChange) {
      if (this.EFTData.accountNumber !== this.originalEFTData.accountNumber) {
        this.EFTData.routingNumber = '';
        this.EFTData.nameOnBank = '';
      }

      if (this.EFTData.routingNumber !== this.originalEFTData.routingNumber) {
        this.EFTData.accountNumber = '';
        this.EFTData.nameOnBank = '';
      }

      if (this.EFTData.nameOnBank !== this.originalEFTData.nameOnBank) {
        this.EFTData.accountNumber = '';
        this.EFTData.routingNumber = '';
      }

      this.nameOnBankValidation.hasError = true;
      this.routingNumberValidation.hasError = true;
      this.accountNumberValidation.hasError = true;
      this.shouldClearFieldsOnChange = false;
    }
  }

  clearEFTInfo(): void {
    this.EFTData.nameOnBank = '';
    this.EFTData.routingNumber = '';
    this.EFTData.accountNumber = '';
    this.EFTData.accountType = this.accountTypeList.checking;
  }

  cancelEFT(): void {
    this.clearEFTInfo();
    this.cancelEvent.emit();
  }

  authorizeEFT(): void {

    if (this.EFTData.accountNumber && this.EFTData.accountNumber === this.originalEFTData.accountNumber) {
      this.accountNumberValidation.hasError = false;
    }

    this.nameOnBankValidation.forceValidation = this.nameOnBankValidation.hasError;
    this.routingNumberValidation.forceValidation = this.routingNumberValidation.hasError;
    this.accountNumberValidation.forceValidation = this.accountNumberValidation.hasError;

    if (!this.nameOnBankValidation.hasError && !this.routingNumberValidation.hasError && !this.accountNumberValidation.hasError) {
      this.EFTDataChange.emit(this.EFTData);
      this.authorizeEvent.emit(this.EFTData);
    }
  }
}
