import { Component, OnInit, Input } from '@angular/core';
import { NeedToSignStatus } from 'src/app/utilities/constants/esign';
import Endpoints from 'src/app/utilities/constants/endpoints';
import Policy from 'src/app/utilities/models/policy';
import { RoutingService } from 'src/app/utilities/services/routing-service/routing.service';
import EsignSessionPolicy from 'src/app/utilities/models/esignSessionPolicy';
import { EsignService } from 'src/app/utilities/services/esign-service/esign.service';
import { AnalyticsService } from 'src/app/utilities/services/analytics-service/analytics.service';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { Alert, AlertType } from 'src/app/utilities/models/alert';

@Component({
  selector: 'app-esign-notification',
  templateUrl: './esign-notification.component.html',
  styleUrls: ['./esign-notification.component.scss']
})

export class EsignNotificationComponent implements OnInit {
  @Input() policies: Policy[] = [];
  @Input() pageName: string;
  alerts: Alert[] = [];
  agentEsignPolicies: Policy[] = [];
  accountEsignPolicies: Policy[] = [];
  esignPolicies: EsignSessionPolicy[] = [];
  url = Endpoints.url;

  EsignTypeMessage: any = {
    ESIGN_TITLE: "You have new documents to sign. ",
    ACCOUNT_ESIGNALERT_MESSAGE: (pageName) => `Avoid changes to your coverage and { "text" : "sign your documents", "url" : "${this.url.esign}", "heapId" : "${pageName}_link_StartEsign" }. `,
    AGENT_ESIGNALERT_MESSAGE:
      "Check your email for instructions from DocuSign or call your agent for more information.",
  };

  constructor(
    public routingService: RoutingService,
    private esignService: EsignService,
    private analyticsService: AnalyticsService,
    private commonService: CommonService
  ) { }

  async ngOnInit() {
    await this.setEsignPolicyNotification();
  }


  async setEsignPolicyNotification() {
    // old function before LMDS implementation
    // Get status from esign api
    const accountEsignPolicies = await this.getAccountEsignPolicies();
    const agentEsignPolicies = await this.getAgentEsignPolicies();
    // Docusign policies contain D (agent esign); esignLive is V (account esign)
    // don't include any Michigan auto docusign policies
    if (accountEsignPolicies && accountEsignPolicies.length ) {
      this.analyticsService.trackAnalytics(this.pageName + "_esign_onLoad_esignLive");
      this.createEsignAlert(
        this.EsignTypeMessage.ACCOUNT_ESIGNALERT_MESSAGE(this.pageName),
        this.EsignTypeMessage.ESIGN_TITLE
      );
    } else if (agentEsignPolicies && agentEsignPolicies.length) {
      this.analyticsService.trackAnalytics("LP_esign_onLoad_Docusign");
      this.createEsignAlert(
        this.EsignTypeMessage.AGENT_ESIGNALERT_MESSAGE,
        this.EsignTypeMessage.ESIGN_TITLE
      );
    }
  }

  /**
   * Combined EsignAlert for policyholders/agents. setEsignPolicyNotification contains
   * business logic for account vs. agent asignment.
   */
  createEsignAlert(message: string, title: string) {
    // create new alert
    const esignAlert = new Alert(AlertType.CAUTION);

    // assign title
    esignAlert.title = title;
    esignAlert.noTitleCase = true;

    // push message according to alert type
    esignAlert.messages.push(message);

    // push alert once created
    this.alerts.push(esignAlert);
  }

  async getAccountEsignPolicies() {
    try {
      const accountEsignPolicy = await this.esignService.getAllEsignPolicies(this.policies);
      return accountEsignPolicy;
    } catch (error) {
      console.log(error);
    }
  }

  async getAgentEsignPolicies() {
    try {
      const docusignSessions = await this.esignService.getDocusignPolicies();

      if (docusignSessions && docusignSessions.length) {
        const agentEsignPolicy = this.policies.filter((policy) => this.hasEsignPolicy(policy, docusignSessions) && !this.isMichiganAutoPolicy(policy));
        return agentEsignPolicy;
      }
    } catch (error) {
      console.log(error);
    }
  }

  hasEsignPolicy(policy: Policy, docusignSessions: any[]) {
    let hasEsignPolicy: boolean = false;

    if (policy && docusignSessions && docusignSessions.length) {
      if (docusignSessions.some((session) => session.eSignInfo.policyNumber === policy.number)) {
        hasEsignPolicy = true;
      }
    }

    return hasEsignPolicy;
  }

  isMichiganAutoPolicy(policy: Policy) {
    let isMichiganAutoPolicy: boolean = false;
    if (policy.ratingStateAlpha === "MI" && policy.policyType === "Auto") {
      isMichiganAutoPolicy = true;
    }

    return isMichiganAutoPolicy;
  }
}
