import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { share } from 'rxjs/operators';
import html2PDF from 'jspdf-html2canvas';

import {
  AnalyticsApplicationNames,
  AnalyticsEventTypes,
  AnalyticsTransactions,
  ViewDocsAppDetailsLookup,
  ViewDocsAppNameLookup,
} from '../../constants/analytics';
import { DocumentTypesMeta } from '../../constants/documentTypes';
import Endpoints from '../../constants/endpoints';

import { FileData } from '../../models/fileData';
import { ServiceTransactionAnalytics } from '../../models/analytics';
import DocumentMeta from '../../models/documentMeta';
import MailDocumentRequest from '../../models/documentRequest';
import Policy from '../../models/policy';

import { AnalyticsService } from '../../services/analytics-service/analytics.service';
import { CommonService } from '../../services/common-service/common.service';

import AnalyticsMethods from '../../methods/analytics.methods';
import DocumentMethods from '../../methods/document.methods';

import { RequestCache } from '../../interceptors/request-cache.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  getBillingDocumentsObs: Observable<DocumentResponse> = null;
  getBillingDocumentUrlObs: Observable<DocumentUrlResponse> = null;
  getPolicyDocumentsObs: Observable<DocumentResponse> = null;
  getPolicyDocumentsUrlObs: Observable<DocumentUrlResponse> = null;
  mailDocumentsUrlObs: Observable<any> = null;

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private analyticsService: AnalyticsService,
    private location: Location,
    private requestCache: RequestCache,
  ) { 
  }

  getPolicyDocuments(policies: Policy[]): Promise<DocumentMeta[]> {
    const errorHeapId: string = 'MMA-View-NotificationSystemError|GetPolicyDocuments';
    const path: string = this.location.path();
    const policyNumbers: string = DocumentMethods.getPolicyNumbersForPolicyDocs(policies);

    if (!this.getPolicyDocumentsObs) {
      this.getPolicyDocumentsObs = this.http.get<DocumentResponse>(Endpoints.api.getDocuments(policyNumbers), { withCredentials: true }).pipe(share());
    }

    return new Promise((resolve, reject) => {
      this.getPolicyDocumentsObs.subscribe({
        next: (response) => {
          if (response && response.status && response.status.code === 200) {
            resolve(response.result);
          } else {
            this.analyticsService.trackAnalytics(errorHeapId);
            reject({ error: 'getPolicyDocumentsError: ' + response.status.code });
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  getPolicyDocumentUrl(contentId: string): Promise<DocumentMeta> {
    const errorHeapId = 'MMA-View-NotificationSystemError|GetPolicyDocumentUrl';
    const path = this.location.path();

    this.requestCache.cacheBust(Endpoints.api.getPolicyDocumentUrl(contentId))
    this.getPolicyDocumentsUrlObs = this.http.get<DocumentUrlResponse>(Endpoints.api.getPolicyDocumentUrl(contentId), { withCredentials: true });

    return new Promise((resolve, reject) => {
      this.getPolicyDocumentsUrlObs.subscribe({
        next: (response) => {
          if (response && response.status && response.status.code === 200 && response.result && response.result.documentUrl) {
            resolve(response.result);
          } else {
            this.analyticsService.trackAnalytics(errorHeapId);
            reject({ error: 'getPolicyDocumentUrlError: ' + response.status.code });
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  getBillingDocuments(policyNumbers: string): Promise<DocumentMeta[]> {
    const errorHeapId = 'MMA-View-NotificationSystemError|GetBillingDocuments';
    const path = this.location.path();
    
    if (!this.getBillingDocumentsObs) {
      this.getBillingDocumentsObs = this.http.get<DocumentResponse>(Endpoints.api.getBillingDocuments(policyNumbers), { withCredentials: true }).pipe(share());
    }

    return new Promise((resolve, reject) => {
      this.getBillingDocumentsObs.subscribe({
        next: (response) => {
          if (response && response.status && response.status.code === 200) {
            resolve(response.result);
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: 'getBillingDocumentsError: ' + response.status.code });
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  getBillingDocumentUrl(contentId: string): Promise<DocumentMeta> {
    const errorHeapId = 'MMA-View-NotificationSystemError|GetBillingDocumentUrl';
    const path = this.location.path();
    
    this.requestCache.cacheBust(Endpoints.api.getBillingDocumentUrl(contentId))
    this.getBillingDocumentUrlObs = this.http.get<DocumentUrlResponse>(Endpoints.api.getBillingDocumentUrl(contentId), { withCredentials: true });

    return new Promise((resolve, reject) => {
      this.getBillingDocumentUrlObs.subscribe({
        next: (response) => {
          if (response && response.status && response.status.code === 200 && response.result && response.result.documentUrl) {
            resolve(response.result);
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: 'getBillingDocumentUrlError: ' + response.status.code });
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  private async getDocumentUrlByType(documentMeta: DocumentMeta): Promise<DocumentMeta> {
    let docMetaWithLink: DocumentMeta;

    try {
      if (documentMeta.documentType === DocumentTypesMeta.POLICY) {
        docMetaWithLink = await this.getPolicyDocumentUrl(documentMeta.contentId);
      } else {
        docMetaWithLink = await this.getBillingDocumentUrl(documentMeta.contentId);
      }
    } catch (err) {
      docMetaWithLink = null;
    }

    return docMetaWithLink;
  }

  async openDocument(documentMeta: DocumentMeta, policyNumber: string): Promise<void> {
    const isMobileApp: boolean = this.commonService.isMobileApp();

    this.trackDocumentMetaClickAnalytics(documentMeta, policyNumber);
    
    const docTab = (!isMobileApp) ? window.open('', '_blank') : window; //opens new tab for desktop app to prevent popup blockers

    if (documentMeta.documentType === 'vaHome') {
      docTab.location.href = documentMeta.contentUrl;
    }
    else {
      const docMetaWithLink: DocumentMeta = await this.getDocumentUrlByType(documentMeta);
      if (docMetaWithLink && docMetaWithLink.downloadUrl) {
        docTab.location.href = docMetaWithLink.downloadUrl;
      }
    }
  }

  setCommonClickAnalytics(policyNumber: string): { transactionType: string, eventType: string, policies: string[] } {
    const transactionType: string = AnalyticsTransactions.VIEW_DOCS;
    const eventType: string = AnalyticsEventTypes.COMPLETE;
    const policies: string[] = [policyNumber];

    return { transactionType, eventType, policies };
  }

  trackTempIdClickAnalytics(policyNumber: string): void {
    if (this.commonService.features?.docsAnalyticsLaunched) {
      const { transactionType, eventType, policies } = this.setCommonClickAnalytics(policyNumber);
      const appName: string = AnalyticsApplicationNames.VIEW_DOCS_TEMP_CARD;
      const serviceAnalytics: ServiceTransactionAnalytics = AnalyticsMethods.buildServiceTransactionAnalytics(policies, appName, transactionType, '', eventType);
  
      this.analyticsService.trackServiceTransactionLinkAnalytics(serviceAnalytics);
    }
  }

  trackDocumentMetaClickAnalytics(documentMeta: DocumentMeta, policyNumber: string): void {
    if (this.commonService.features?.docsAnalyticsLaunched) {
      const { transactionType, eventType, policies } = this.setCommonClickAnalytics(policyNumber);
      const appName: string = ViewDocsAppNameLookup[documentMeta.documentTypeCode] || '';
      const appDetails: string = ViewDocsAppDetailsLookup[documentMeta.documentTypeCode] || '';
      const serviceAnalytics: ServiceTransactionAnalytics = AnalyticsMethods.buildServiceTransactionAnalytics(policies, appName, transactionType, appDetails, eventType);

      this.analyticsService.trackServiceTransactionLinkAnalytics(serviceAnalytics);
    }
  }

  mailDocuments(documentRequest: MailDocumentRequest): Promise<void> {
    this.requestCache.cacheBust(Endpoints.api.orderMailDocuments);
    this.mailDocumentsUrlObs = this.http.post<HttpResponse<MailDocumentResponse>>(Endpoints.api.orderMailDocuments, documentRequest, { withCredentials: true }).pipe(share());

    const errorHeapId = 'MMA-View-NotificationSystemError|OrderDocuments';
    const path = this.location.path();

    return new Promise((resolve, reject) => {
      this.mailDocumentsUrlObs.subscribe({
        next: (response: MailDocumentResponse) => {
          if (response && response.status && response.status.code === 202) {
            resolve();
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject();
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }
  
  async generatePdfFromHtml(htmlElement: HTMLElement): Promise<any> {
    try {
      const options: any = {
        jsPDF: {
          unit: 'px',
          format: 'a4',
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        autoResize: true,
        html2canvas:  { 
          width: (document?.body?.clientWidth) ? ((document.body.clientWidth > 850) ? 850 : (document.body.clientWidth < 575) ? 575 : document.body.clientWidth) : 850,
          height: 1000
        },
        output: 'Evidence_of_coverage.pdf',
        init: function() {},
        success: function() {}
      }

      // hide the element as it's prepended to body
      htmlElement.style.zIndex = '-1';
      htmlElement.style.position = 'absolute'

      document.body.prepend(htmlElement);
      const pdf = await html2PDF(htmlElement, options);
      document.body.removeChild(htmlElement);

      return pdf;
    } catch (error) {
      console.log(error); 
    }
  }

  async convertPdfToBase64(pdf: any): Promise<FileData> {
    try {
      const blob = await fetch(pdf.output('bloburl')).then(r => r.blob());
      const base64Data = await this.readBase64(blob);
      let fileData = new FileData(base64Data);
      return fileData;
    } catch (error) {
      console.log(error);
    }
  }

  private readBase64(blob: any): Promise<any> {
    const reader = new FileReader();
    const pdfBytes = new Promise((resolve, reject) => {
      reader.addEventListener('load', function () {
        resolve(reader.result);
      }, false);
      reader.addEventListener('error', function (event) {
        reject(event);
      }, false);
    
      reader.readAsDataURL(blob);
    });

    return pdfBytes;
  }
}

class DocumentResponse {
  result: DocumentMeta[];
  status: {
    code: number;
  };
}

class DocumentUrlResponse {
  result: DocumentMeta;
  status: {
    code: number;
  };
}

interface MailDocumentResponse {
  result: string,
  status: {
    code: number
  }
}

