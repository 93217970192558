import NotificationMessageId from 'src/app/utilities/constants/notificationMessageId';

import BillingMethods from 'src/app/utilities/methods/billing.methods';

import { CreditCardInfo } from 'src/app/utilities/models/creditCard';
import { FieldValidation, ValidationRegex } from 'src/app/utilities/models/fieldValidation';
import Billing from 'src/app/utilities/models/billing';
import EFTInfo from 'src/app/utilities/models/eftInfo';
import Notification from 'src/app/utilities/models/notification';
import { CustomerSearchPolicy } from './customerSearch';
import Policy from './policy';
import { ScheduledPaymentDetails } from './payments';
import PolicyMethods from '../methods/policy.methods';
import PaymentsMethods from '../methods/payments.methods';

export class BillingPaymentAccount {
    autopayDisclaimer: string;
    accountMessage: string;
    accountMessageClass: string = 'basic-message';
    amountDue: number;
    amountToPayDisplay: string = '0';
    amountToPayValidation: FieldValidation = new FieldValidation(false);
    amountToPayValue: number = 0;
    billingAccountNumber: string;
    contextGuid: string;
    displayDefaultPaymentError: boolean = false;
    displayDuplicatePaymentError: boolean = false;
    isActive: boolean = true;
    isActiveWithZeroBalance: boolean = false;
    isAmountToPayCurrentAmountDue: boolean = false;
    isAmountToPayOtherAmount: boolean = false;
    isAmountToPayTotalBalance: boolean = false;
    isCreditCardProfileFound: boolean = false;
    isCurrentAmountDue: boolean = false;
    isInactiveWithBalance: boolean = false;
    isBillingAccountPayable: boolean = false;
    isPastDue: boolean = false;
    isPayable: boolean = false;
    isRegularPay: boolean = false;
    isSelected: boolean = false;
    isPayrollDeduction: boolean = false;
    isPayByMortgagee: boolean = false;
    lastPaymentDate: Date;
    nextBillAmount: number;
    nextBillDate: string;
    nextPaymentDate: string;
    paymentConfirmationNumber: string;
    paymentMethod: string;
    isAutopay: boolean;
    paymentResponse: BillingPaymentAccountResponse;
    scheduleMakePaymentResponse: ScheduleMakePaymentAccountResponse;
    scheduledMakePayments: ScheduledPaymentDetails[];
    currentScheduledPayment: ScheduledPaymentDetails;
    policies: string[] = [];
    policyStates: string[] = [];
    policyNumber: string;
    recurringCreditCardInfo: CreditCardInfo;
    recurringEFTInfo: EFTInfo;
    requestGuid: string;
    searchPolicies: CustomerSearchPolicy[];
    totalBalanceDisplay: string = '0';
    totalBalanceValue: number = 0;

    constructor(account?: Billing, renewalPremium?: number) {
        this.amountToPayValidation.validations = [{
            pattern: ValidationRegex.numericAndEmptyStringOnly
        }];

        if (account) {
            this.billingAccountNumber = account.isPayByMortgagee ? account.policyNumber : account.number;
            this.nextPaymentDate = account.nextPaymentDate;
            this.nextBillDate = account.nextBillDate;
            this.nextBillAmount = account.nextBillAmount;
    
            this.buildPoliciesForDisplay(account);
            this.setAmounts(account);
            this.checkActiveAndPastDueStatuses(account);
            this.buildInlineNotifications(account);
    
            this.paymentMethod = PaymentsMethods.getPaymentMethodDisplay(account);
            this.isActiveWithZeroBalance = !!(this.isActive && this.amountToPayValue === 0);
            this.isPayable = this.isAccountPayable(account, renewalPremium);
            this.isCreditCardProfileFound = account.isCreditCardProfileFound;
            this.isBillingAccountPayable = account.isBillingAccountPayable;
            this.contextGuid = account.contextGUID;
            this.requestGuid = account.requestGUID;
            this.policyNumber = BillingMethods.getPolicyNumberFromBillingAccount(account);
            this.isAutopay = BillingMethods.isAutoPay(account);

            if (this.isAutopay) {
                this.recurringCreditCardInfo = account.recurrCreditCardData;
                this.recurringEFTInfo = account.recurrEFTData;
            }

            this.isRegularPay = BillingMethods.isRegularPay(account);
            this.scheduledMakePayments = account.scheduledMakePayments;
        }
    }

    private buildPoliciesForDisplay(account: Billing): void {
        if (account.policyList && account.policyList.length) {
            account.policyList.forEach((policy: Policy) => {
                if (PolicyMethods.IsPolicyActiveOrCanceledWithBalance(policy)) {
                    this.policies.push(policy.number);
                    
                    if (!this.policyStates.includes(policy.ratingStateAlpha)) {
                        this.policyStates.push(policy.ratingStateAlpha);
                    }
                }
            });
        }
    }

    private setAmounts(account: Billing): void {
        this.totalBalanceValue = BillingMethods.getTotalBalanceToDisplay(account);
        this.totalBalanceDisplay = this.totalBalanceValue.toString();
        this.totalBalanceDisplay = parseFloat(this.totalBalanceDisplay).toFixed(2);

        if (account.amountDue && account.amountDue > 0) {
            this.amountDue = account.amountDue;
            this.amountToPayValue = account.amountDue;
            this.isCurrentAmountDue = true;
        } else if (account.totalBalanceRemainingAmount && account.totalBalanceRemainingAmount > 0) {
            this.amountToPayValue = this.totalBalanceValue;
        }

        this.amountToPayDisplay = this.amountToPayValue.toString();
        this.amountToPayDisplay = parseFloat(this.amountToPayDisplay).toFixed(2);
    }

    private checkActiveAndPastDueStatuses(account: Billing): void {
        if (account.isInactiveOrCanceledAccount) {
            this.isInactiveWithBalance = (this.amountToPayValue > 0);
        } else {
            this.isPastDue = account.isPastDueAccount;
        }

        this.isActive = !account.isInactiveOrCanceledAccount;
    }

    private buildInlineNotifications(account: Billing): void {
        account.notificationsList.forEach((notification: Notification) => {
            switch (notification.messageId) {
                case NotificationMessageId.NEW_BUSINESS:
                case NotificationMessageId.PAYMENT_PENDING:
                case NotificationMessageId.RTB_PAYMENT_SUCCESS:
                    this.accountMessage = notification.notificationMessage;
                    this.accountMessageClass = 'basic-message';
                    break;
                case NotificationMessageId.CANCELED_ACCOUNT_W_BALANCE:
                case NotificationMessageId.POST_MRK_DATE_OVERDUE:
                case NotificationMessageId.REG_PAY_PAST_DUE:
                    this.accountMessage = notification.notificationMessage;
                    this.accountMessageClass = 'error-message';
                    break;
                case NotificationMessageId.RCC_DECLINED:
                    this.accountMessage = notification.heading + '. ' + notification.notificationMessage;
                    break;
                default:
                    break;
            }
        });
    }

    private isAccountPayable(account: Billing, renewalPremium?: number): boolean {
        let isAccountPayable = false;

        if (account.isPayByMortgagee) {
            if (BillingMethods.isMortgageeAccountPayable(account, renewalPremium)) {
                isAccountPayable = true;
            }
        } else if (!this.isInactiveWithBalance && this.amountToPayValue && !account.isPayrollDeduction) {
            isAccountPayable = true;
        }

        return isAccountPayable;
    }
}

export class BillingPaymentAccountResponse {
    statusCode: number;
    statusMessage: string;
    paymentConfirmationNumber: string;
}

export class PaymentStorage {
    contextGuid: string;
    creditCardInfo: CreditCardInfo;
    eftInfo: EFTInfo;
    endpointPolicyNumber: string;
    billingAccountNumber: string;
    isBankWithdrawal: boolean;
    isCreditCard: boolean;
    paymentMethodOutput: string;
    paymentResponse: PaymentStorageResponse = new PaymentStorageResponse();
    requestGuid: string;
    selectedAccounts: BillingPaymentAccount[] = [];
    totalBalance: number = 0;
    deleteStorageOnRestart: boolean = false;
    pageEdit: boolean = false;
}

export class PaymentStorageResponse {
    confirmationNumbers: string[] = [];
    paymentDate: Date;
    statusCode: number;
    statusMessage: string;
    withdrawalDate: Date;
}


export class ScheduleMakePaymentAccountResponse {
    statusCode: string;
    statusMessage: string;
    paymentConfirmationNumber: string;
}
