import { Injectable } from '@angular/core';
import Agency from '../../models/agency';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class Cookies {
    halfHourInMilliseconds: number = 1800 * 1000;

    constructor(private cookieService: CookieService) {
    }

    setAgentCookieExpirationDate(): string {
        // following the same expiry setting MMA is using for cookies, hence the addition of 5 years
        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 5);
        return 'expires=' + expires.toUTCString();
    }

    setPaperlessPolicyExpirationDate(): Date {
        const expires = new Date();
        expires.setDate(expires.getDate() + 60);
        return expires;
    }

    setExpireInOneDayDate(): Date {
      const expires = new Date();
      expires.setDate(expires.getDate() + 1);
      return expires;
    }

    setExpireIn5Mins(): Date {
      const expires = new Date();
      expires.setTime(expires.getTime() + (5 * 60 * 1000));
      return expires;
    }

    setRenewalExpirationDate(): Date {
      const expires = new Date();
      expires.setDate(expires.getTime() + 6 * 3600 * 1000);
      return expires;
    }

    setMMAOriginalURLExpirationDate(): Date {
      const expires = new Date();
      expires.setFullYear(expires.getFullYear() + 5);
      const UTCstring = expires.toUTCString();
      const UTCDate = new Date(UTCstring);
      return UTCDate;
    }

    setMMAUsernameExpirationDate(): Date {
      const expires = new Date();
      expires.setTime(expires.getTime() + this.halfHourInMilliseconds);
      return expires;
    }

    setMMATempCodeExpirationDate(): Date {
      const expires = new Date();
      expires.setTime(expires.getTime() + this.halfHourInMilliseconds);
      return expires;
    }

    setMMAAlertExpirationDate(): Date {
      const expires = new Date();
      expires.setTime(expires.getTime() + this.halfHourInMilliseconds);
      return expires;
    }

    setMMAQParamExpirationDate(): Date {
      const expires = new Date();
      expires.setTime(expires.getTime() + 10 * 1000);
      return expires;
    }

    setMMAInactivityExpirationDate(): Date {
      const expires = new Date();
      expires.setTime(expires.getTime() + (30 * 1000));// Expiring in 30 seconds
      return expires;
    }
  
    setRememberMeExpirationDate(): Date {
      const expires = new Date();
      expires.setFullYear(expires.getFullYear() + 2);
      const UTCstring = expires.toUTCString();
      const UTCDate = new Date(UTCstring);
      return UTCDate;
    }

    setPwolCCExpirationDate(): Date {
      const expires = new Date();
      expires.setMonth(expires.getMonth() + 6);
      return expires;
    }

    setGuidExpirationDate(): Date {
      const expires = new Date();
      expires.setDate(expires.getTime() + 2 * 1000);
      return expires;
  }

    public createMMAAgentInfoCookie(agency: Agency) {
        if (agency) {
            document.cookie = 'MMAAgentInfoCookie=' + 
            '"AgencyName=' + (agency.agencyName || '') +
            '|AgencyAddress1=' + (agency.addressLine1 || '') +
            '|AgencyAddress2=' + (agency.addressLine2 || '') +
            '|AgencyCity=' + (agency.city || '') +
            '|AgencyState=' + (agency.state || '') +
            '|AgencyZIPCode=' + (agency.zipCode || '') +
            '|AgencyPhone=' + (agency.phoneNumber || '') +
            '|AgencyEmail=' + (agency.emailAddress || '') +
            '|AgentType=' + (agency.agentType || '') +
            '|AgentNumber=' + (agency.agentNumber || '') +
            '|Banner=' + (agency.banner || '') +
            '|BannerImage=' + (agency.bannerImage || '') +
            '|AgencyWebSite=' + (agency.agencyWebSite || '') +
            '|AgencyStatCode=' + (agency.agencyStatCode || '') + '";' +
            this.setAgentCookieExpirationDate() + ';path=/; domain=safeco.com';
        }
    }

    public setPaperlessPolicyCookie(policyFlag: string) {
        this.cookieService.set('PaperlessPolicyCookie', policyFlag, this.setPaperlessPolicyExpirationDate(), '/', 'safeco.com', false, 'Strict');           
    }

    public getPaperlessPolicyCookie(): string {
      return this.cookieService.get('PaperlessPolicyCookie');  
    }

    public getMMAOriginalURLCookie(): string {
      return this.cookieService.get('MMAOriginalURL');
    }

    public setRenewalCookie(policyFlag: string) {
        this.cookieService.set('renewal', policyFlag, this.setRenewalExpirationDate(), '/', 'safeco.com', false, 'Strict');           
    }
  
    public getRenewalCookie(): string {
      return this.cookieService.get('renewal');
    }

    public setRememberMeCookie() {
      this.cookieService.set('MMARememberMe', 'Y', this.setRememberMeExpirationDate(), '/', 'safeco.com', false, 'Strict');
    }

    public getRememberMeCookie(): string {
      return this.cookieService.get('MMARememberMe');
    }

    public getGuidCookie(): string {
      return this.cookieService.get('MMAGuid');
    }

    public getMMASavedUserIDCookie(): string {
      if (this.cookieService.get('MMASavedUserIDDEV') !== '') { /* prod stores username in DEV cookie; check then move to generic name; delete after some time? */
        this.cookieService.set('MMASavedUserID', this.cookieService.get('MMASavedUserIDDEV'), this.setRememberMeExpirationDate(), '/', 'safeco.com', false, 'Strict');
        this.clearCookie('MMASavedUserIDDEV');
      }
      return this.cookieService.get('MMASavedUserID');
    }

    public setMMAUsernameCookie(username: string) {
      this.cookieService.set('MMAUsername', username, this.setMMAUsernameExpirationDate(), '/', 'safeco.com', false, 'Strict');
      //set domain to localhost for local testing:
      //this.cookieService.set('MMAUsername', username, this.setMMAUsernameExpirationDate(), '/', 'localhost', false, 'Strict');
    }

    public getMMAUsernameCookie(): string {
      return this.cookieService.get('MMAUsername');
    }

    public setMMATempCodeCookie(passcode: string) {
      this.cookieService.set('MMATempCode', passcode, this.setMMATempCodeExpirationDate(), '/', 'safeco.com', false, 'Strict');
    }

    public getMMATempCodeCookie(): string {
      return this.cookieService.get('MMATempCode');
    }

    public setMMAInactivityCookie(flag: string) {
      this.cookieService.set('MMAInactivity', flag, this.setMMAInactivityExpirationDate(), '/', 'safeco.com', false, 'Strict');
    }

    public getMMAInactivityCookie(): string {
      return this.cookieService.get('MMAInactivity');
    }

    public setMMAAlertCookie(alerttype: string) {
      this.cookieService.set('MMAAlert', alerttype, this.setMMAAlertExpirationDate(), '/', 'safeco.com', false, 'Strict');
    }

    public getMMAAlertCookie(): string {
      return this.cookieService.get('MMAAlert');
    }

    public setMMAQParamCookie(loaded: string) {
      this.cookieService.set('MMAQParam', loaded, this.setMMAQParamExpirationDate(),  '/', 'safeco.com', false, 'Strict');
    }

    public setGuidCookie(loaded: string) {
      //this.cookieService.set('MMAGuid', loaded, this.setGuidExpirationDate(),  '/', 'localhost', false, 'Strict');
      this.cookieService.set('MMAGuid', loaded, this.setGuidExpirationDate(),  '/', 'safeco.com', false, 'Strict');
    }

    public getMMAQParamCookie(): string {
      return this.cookieService.get('MMAQParam');
    }

    public setMMALoginCookie(loaded: string) {
      this.cookieService.set('MMALogin', loaded, 0, '/', 'safeco.com', false, 'Strict');  //change to safeco.com  or localhost
    }

    public getMMALoginCookie(): string {
      return this.cookieService.get('MMALogin');
    }

    public setMMAGuidCookie(loaded: string) {
      this.cookieService.set('MMAGuid', loaded, 0, '/', 'safeco.com', false, 'Strict');
    }

    public getMMADestUrlCookie(): string {
      return this.cookieService.get('MMADestUrl');
    }
    
    public setMMADestUrlCookie(url: string) {
      this.cookieService.set('MMADestUrl', url, 0, '/', 'safeco.com', false, 'Strict');
    }

    public getMMASavedUserIDDEVAPPCookie(): string {
      return this.cookieService.get('MMASavedUserIDDEVAPP');
    }

    public setMMAWayfinderCookie(stage: string) {
      this.cookieService.set('MMAWayfinder', stage, 0, '/', 'safeco.com', false, 'Strict');
    }

    public getMMAWayfinderCookie(): string {
      return this.cookieService.get('MMAWayfinder');
    }
    public setHeaderFlashCookie() {
        this.cookieService.set('HeaderFlash', 'y', this.setExpireIn5Mins(), '/', 'safeco.com', false, 'Strict');
    }

    public getHeaderFlashCookie(): string {
        return this.cookieService.get('HeaderFlash');
    }

    public setMMAEsignRedirectCookie() {
      this.cookieService.set('MMAEsignRedirect', 'Y', 0, '/', 'safeco.com', false, 'Strict');
    }

    public getMMAEsignRediectCookie(): string {
      return this.cookieService.get('MMAEsignRedirect');
    }

    public clearCookie(name: string) {
      this.cookieService.delete(name, '/', 'safeco.com');
    }
  
}
