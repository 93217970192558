import { HttpParams } from "@angular/common/http";
import { AnalyticsApplicationNames, AnalyticsTransactions } from "../constants/analytics";
import Endpoints from "../constants/endpoints";
import { ServiceTransactionAnalytics, ServiceTransactionUtagAnalytics } from "../models/analytics";
import Billing from "../models/billing";
import { BillingPaymentAccount } from "../models/billingPaymentAccount";
import Policy from "../models/policy";
import BillingMethods from "./billing.methods";
import PolicyMethods from "./policy.methods";
//import {PolicyChangeTypes, PolicyChangeFlows, PolicyChangeViews, PolicyChangeModes} from "../constants/policyChange";

export default class AnalyticsMethods {
    public static buildMakePaymentAnalytics(isAuth: boolean, analyticsEventType: string, paymentAccounts: BillingPaymentAccount[], applicationDetails: string): ServiceTransactionAnalytics {
        const makePaymentAnalytics: ServiceTransactionAnalytics = new ServiceTransactionAnalytics();

        // separate method for MAP/PWOL, since BillingPaymentAccount uses slightly different models/fields
        if (analyticsEventType && paymentAccounts && paymentAccounts.length) {
            makePaymentAnalytics.dscEventType = analyticsEventType;
            makePaymentAnalytics.dscTransactionType = AnalyticsTransactions.PAYMENT;
            makePaymentAnalytics.dscApplicationName = isAuth ? AnalyticsApplicationNames.MAP : AnalyticsApplicationNames.PWOL;
            makePaymentAnalytics.dscApplicationDetail = applicationDetails;
            makePaymentAnalytics.polNum = BillingMethods.getPolicyNumbersStringFromPaymentAccounts(paymentAccounts, isAuth);

            if (paymentAccounts[0].policyStates && paymentAccounts[0].policyStates.length) {
                makePaymentAnalytics.jurisdiction = paymentAccounts[0].policyStates[0];
            }
        }

        return makePaymentAnalytics;
    }

    public static buildServiceTransactionAnalytics(policies: any[], analyticsApplicationName: string, analyticsTransactionType: string, applicationDetails: string, analyticsEventType: string, policyStates?: string[]): ServiceTransactionAnalytics {
        const analytics: ServiceTransactionAnalytics = new ServiceTransactionAnalytics();

        if (policies && policies.length) {
            analytics.dscTransactionType = analyticsTransactionType; // e.g. AnalyticsTransactions.RPP
            analytics.dscApplicationName = analyticsApplicationName; // e.g. AnalyticsApplicationNames.ENROLL_AUTOPAY
            analytics.dscApplicationDetail = applicationDetails;  // send in blank for "start" ones
            analytics.dscEventType = analyticsEventType; // start or Complete
            analytics.polNum = PolicyMethods.getPolicyNumbersStringFromPolicyList(policies);
            analytics.jurisdiction = (policyStates && policyStates.length) ? policyStates[0] : PolicyMethods.getFirstPolicyStateFromPolicyList(policies);
        }

        return analytics;
    }

    public static buildServiceTransactionUtagAnalytics(serviceTransactionAnalytics: ServiceTransactionAnalytics, contentType: string): ServiceTransactionUtagAnalytics {
        const utagAnalytics: ServiceTransactionUtagAnalytics = new ServiceTransactionUtagAnalytics();

        if (serviceTransactionAnalytics && contentType) {
            utagAnalytics.analyticsObject = serviceTransactionAnalytics;
            utagAnalytics.contentType = contentType;
            utagAnalytics.contentID = `${serviceTransactionAnalytics.dscApplicationName}-${serviceTransactionAnalytics.dscEventType}`;
        }

        return utagAnalytics;
    }

    // build ServiceTransactionAnalytics for account / unauth flows where those don't have policy info
    public static buildAccountServiceTransactionAnalytics(analyticsApplicationName: string, analyticsTransactionType: string, applicationDetails: string, analyticsEventType: string): ServiceTransactionAnalytics {
        const analytics: ServiceTransactionAnalytics = new ServiceTransactionAnalytics();

        analytics.dscTransactionType = analyticsTransactionType; // e.g. AnalyticsTransactions.AUTH
        analytics.dscApplicationName = analyticsApplicationName; // e.g. AnalyticsApplicationNames.ACCOUNT_REGISTRATION
        analytics.dscApplicationDetail = applicationDetails;
        analytics.dscEventType = analyticsEventType; // Start or Complete
        analytics.polNum = '';
        analytics.jurisdiction = '';

        return analytics;
    }

    
    public static getDataLayerApplicationName(pageSubName: string, pageSubParams?: string): string {
        let applicationName: string = null;
        
        let typeParamValue = '';
        if(pageSubParams) {
            typeParamValue = this.getParamValueQueryString('type', pageSubParams);
        }

        if (pageSubName) {
            switch (pageSubName) {
                case 'changeemail':
                    return AnalyticsApplicationNames.CHANGE_EMAIL;
                case 'checkemail':
                    if(typeParamValue === 'create') {
                        return AnalyticsApplicationNames.ACCOUNT_REGISTRATION;
                    } else if (typeParamValue === 'email') {
                        return AnalyticsApplicationNames.ACCOUNT_ACTIVATION;
                    } else { // type=reset
                        return AnalyticsApplicationNames.ACCOUNT_RECOVERY;
                    }
                case 'createnew':
                        return AnalyticsApplicationNames.ACCOUNT_REGISTRATION;
                case 'payment':
                    return AnalyticsApplicationNames.MAP;
                case 'guest-payment':
                    return AnalyticsApplicationNames.PWOL;
                default:
                    break;
            }
        }

        return applicationName;
    }

    public static getParamValueQueryString(paramName: string, queryParams: string): string {
        const httpParams = new HttpParams({ fromString: queryParams });
        const paramValue = httpParams.get(paramName);
        return (paramValue != null) ? paramValue : '';
    }

    public static getDataLayerTransactionType(pageSubName: string): string {
        let transactionType: string = null;

        if (pageSubName) {
            switch (pageSubName) {
                case 'autopay':
                    return AnalyticsTransactions.RPP;
            }
        }

        return transactionType;
    }

    public static isDataLayerTransactionPage(path: string): boolean {
        if (path) {

            if (path.includes(Endpoints.url.policyEdit) && path.includes("?type=")) { //OPC
                return true;
            }

            switch (path) {
                case Endpoints.url.changeEmail:
                case Endpoints.url.payment:
                case Endpoints.url.paymentConfirmation:
                    return true;
                default:
                    return false;
            }
        }

        return false;
    }
}
