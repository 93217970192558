export class SecureKeyResponse {
  secureKey: string;
  status: string;
  statusDescription: string;
}

export class CreditCardMiniAppData {
  allowPrepaidAndGiftCard: boolean;
  billingAddress: BillingAddress;
  eftValidationType: string;
  paymentAccount: PaymentAccount;
  email: string;
}

export class BillingAddress {
  zipCode: string;
}

export class PaymentAccount {
  accountNickname: string;
  cardBrand: string;
  cardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  firstName: string;
  lastName: string;
  middleInitial: string;
  paymentAccountId: string;
  paymentAccountType: string;
  saveAccount: boolean;
  securityCode: string;
}

export class PrefillCreditCardInfo {
  creditCardData: CreditCardInfo;
  alignedProfileBillingInfo: AlignedCCProfileBillingInfo;
}

export class AlignedCCProfileBillingInfo {
  billingAccountNumber: string;
  contextGuid: string;
}

export class CreditCardInfo {
  billingZipCode: string;
  cardBrandAbbr: string;
  cardHolderFName: string;
  cardHolderLName: string;
  cardHolderMName: string;
  cardLastFour: string;
  cardNumberMasked: string;
  cardType: string;
  ccProfileExists: boolean;
  contextGuid: string;
  emailAddress: string;
  emailNotification: boolean;
  expirationMonth: string;
  expirationYear: string;
  financialAccountID: string;
  firstName: string;
  lastName: string;
  middleInitial: string;
  saveAccount: boolean;
  alignedProfileBillingInfo: AlignedCCProfileBillingInfo;
  instrumentIdCode: string;
  hasPaymentechProfile: boolean;
  paymentSource: string;

  constructor() {
    this.cardBrandAbbr = '';
    this.cardHolderFName = '';
    this.cardHolderMName = '';
    this.cardHolderLName = '';
    this.cardLastFour = '';
    this.cardNumberMasked = '';
    this.cardType = '';
    this.ccProfileExists = false;
    this.contextGuid = '';
    this.emailAddress = '';
    this.expirationMonth = '';
    this.expirationYear = '';
    this.financialAccountID = '';
    this.billingZipCode = '';
    this.instrumentIdCode = '';
    this.saveAccount = true;
    this.emailNotification = true; //always for recurring

    this.firstName = '';
    this.middleInitial = '';
    this.lastName = '';
  }
}
