export default class EFTInfo {
    accountNumber: string;
    accountType: string;
    bankNameAddress: string;
    displayMaskedAccountNumber: string;
    nameOnBank: string;
    routingNumber: string;
    tokenizedAccountNumber: string;

    constructor() {
        this.accountNumber = '';
        this.accountType = '';
        this.bankNameAddress = '';
        this.displayMaskedAccountNumber = '';
        this.nameOnBank = '';
        this.routingNumber = '';
        this.tokenizedAccountNumber = '';
    }
}
