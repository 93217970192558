export default class CAMStorage {
    static storageKeys: any = {
        autopayBillingAccountNumber: '/billing/autopay-billing',
        billingPaymentStorage: '/billing/payment',
        chatSession: 'mma-chat-session',
        crossSellStorage: 'cross-sell-info',
        documentsPolicyNumber: '/documents-policy',
        getInstrumentDetails : 'hydra-get-instruments',
        getPaymentInfoStatus: 'paymentInfoStatus',
        hasBillingChanged: 'ngStorage-hasBillingChanged',
        hydraCCBillingAccounts: 'hydraCCBillingAccounts',
        instrumentDetailsStorage: 'hydra-instrument-details',
        isAnalyticsIdSet: 'isAnalyticsIdSet',
        isRenewalTracked: 'isRenewalTracked',
        isReplaceVehicleEnabled: 'isReplaceVehicleEnabled',
        isRenewalTabSummaryEnabled: 'isRenewalTabSummaryEnabled',
        makePaymentPrefillCreditCardData: 'makePaymentPrefillCreditCardData',
        pendingLandlords: 'pendingLandlords',
        pendingVehicles: 'pendingVehicles',
        pendingLenderRemoval: 'pendingLenderRemoval',
        policyEditNumber: '/policy/edit-policy',
        policyEditTypeParamInfo: '/policy/edit-typeParamInfo',
        policyRenewalDiscount: '/policy/renewaldiscounts-renewal',
        policySummaryNumber: '/policy/summary-policy',
        realtimePendingPayments: 'hydra-rtb-pending',
        renewalBillingGroup: 'renewalBillingGroup',
        renewalPolicyNumbers: 'renewalPolicyNumbers',
        savedInstrumentIdCodeHasError: 'hydra-saved-instrument-code-error',
        savedInstrument: 'hydra-saved-instrument',
        selectPaymentPolicyNumber: '/billing/payment-select',
        vaHomeownersCalled: 'vaHomeownersCalled',
        isUserLoggingOut: 'isUserLoggingOut',
        camDataLayer: {
            channel: 'channel',
            subSection: 'subsection',
            subSubSection: 'subsub',
            testCampaignName: 'T-campaignName',
            testRecipeName: 'T-recipeName',
            agentGold: 'A-gold',
            billingAccountNums: 'B-billingaccountnums',
            billingMultiSingleMAP: 'B-multisingleMAP',
            customerUserID: 'C-userid',
            policyCount: 'P-cntpolsonpage',
            policyCountEsign: 'P-cntofesignpol',
            policyLineOfBusiness: 'P-lineofbusiness',
            policyNumbers: 'P-policynums',
            policyNumbersEsignWarning: 'P-policynumswarning',
            policyRatingStates: 'P-ratingstates',
            policyRenewalDate: 'P-policyRenewalDate',
            singleMultiRenewal: 'P-singleMultiRenewal',
            renewalPolicyNumbers: 'renewalPolicyNumbers',
            renewalBillingGroup: 'renewalBillingGroup'
        }
    };

    static setItemInStorage(key: string, value: any): void {
        if (key) {
            if (typeof value === 'object') {
                value = JSON.stringify(value);
            }

            sessionStorage.setItem(key, value);
        }
    }

    static addItemToArrayInStorage(arrayKey: string, value: any): void {
        if (arrayKey) {
            let array = this.getItemInStorage(arrayKey, true);
            if (!Array.isArray(array)) {
                array = [];
            }
            array.push(value);
            this.setItemInStorage(arrayKey, array);
        }
    }

    static getItemInStorage(key: string, valueIsComplex?: boolean): any {
        const storageHasKey: boolean = Object.prototype.hasOwnProperty.call(sessionStorage, key);
        let storageValue: string = null;

        if (storageHasKey && sessionStorage.getItem(key)) {
            if (valueIsComplex) {
                storageValue = JSON.parse(sessionStorage.getItem(key));
            } else {
                storageValue = sessionStorage.getItem(key);
            }
        }

        return storageValue;
    }

    static removeItemInStorage(key: string): void {
        const storageHasKey: boolean = Object.prototype.hasOwnProperty.call(sessionStorage, key);

        if (storageHasKey) {
            sessionStorage.removeItem(key);
        }
    }

    static clearStorage(): void {
        sessionStorage.clear();
    }

    static getItemAsBool(key: string): boolean {
        if (sessionStorage.getItem(key) === 'true') {
            return true;
        }

        return false;
    }
}

export function setAutopayStorage(billingNumber: string): void {
    if (billingNumber) {
        CAMStorage.setItemInStorage(CAMStorage.storageKeys.autopayBillingAccountNumber, billingNumber);
    } else {
        CAMStorage.removeItemInStorage(CAMStorage.storageKeys.autopayBillingAccountNumber);
    }
}

export function setPolicyDetailStorage(policyNumber: string): void {
    if (policyNumber) {
        CAMStorage.setItemInStorage(CAMStorage.storageKeys.policySummaryNumber, policyNumber);
    } else {
        CAMStorage.removeItemInStorage(CAMStorage.storageKeys.policySummaryNumber);
    }
}

export function setPolicyEditStorage(policyNumber: string): void {
    if (policyNumber) {
        CAMStorage.setItemInStorage(CAMStorage.storageKeys.policyEditNumber, policyNumber);
    } else {
        CAMStorage.removeItemInStorage(CAMStorage.storageKeys.policyEditNumber);
    }
}

export function setDocumentsStorage(policyNumber: string): void {
    if (policyNumber) {
        CAMStorage.setItemInStorage(CAMStorage.storageKeys.documentsPolicyNumber, policyNumber);
    } else {
        CAMStorage.removeItemInStorage(CAMStorage.storageKeys.documentsPolicyNumber);
    }
}

export function setPaymentStorage(policyNumber: string): void {
    if (policyNumber) {
        CAMStorage.setItemInStorage(CAMStorage.storageKeys.selectPaymentPolicyNumber, policyNumber);
    } else {
        CAMStorage.removeItemInStorage(CAMStorage.storageKeys.selectPaymentPolicyNumber);
    }
}
