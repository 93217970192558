export const ModalNames = {
    ADD_DRIVER_INCIDENT: 'addDriverIncidentDescription',
    ADD_VEHICLE_INFO: 'addVehicleInformation',
    CANCEL_SCHEDULE_PAYMENT: 'cancelSchedulePayment',
    CC_AUTHORIZATION: 'CCAuthorizationModal',
    CHANGE_DRIVER_BUSINESS_USE: 'changeDriverBusinessUseInfo',
    CHANGE_PASSWORD: 'changePWModal',
    CROSS_SELL: 'crossSellModal',
    EFT_AUTHORIZATION: 'eftAuthorizationModal',
    EXIT_SURVEY: 'exitSurveyModal',
    MAIL_CERT_SUCCESS: 'mailCertificateSuccess',
    OPT_OUT_SMS_SUCCESS: 'optOutSms',
    PAPERLESS: 'paperlessModal',
    PAPERLESS_CA: 'paperlessCAModal',
    PAYMENT_SETTINGS_SUCCESS: 'paymentSettingsSuccess',
    POLICY_DOCUMENTS: 'policyDocumentsModal',
    POLICY_NUM_IMGS: 'findPolicyNumberImages',
    POSTPONE_PAYMENT_SUCCESS: 'postponePaymentSuccess',
    REPLACE_VEHICLE_INFO: 'replaceVehicleInformation',
    REVIEW_AUTOPAY_UNENROLL: 'reviewAutopayUnenroll',
    ROUTING_ACCT_NUMBER: 'routingAccountNumber',
    SMS_SIGNUP: 'smsSignup',
    UPDATE_AUTOPAY_SUCCESS: 'updateAutopaySuccess',
    VERIFY_REMOVE_POLICY: 'verifyRemovePolicy'
};
