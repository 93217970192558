<div class="credit-card-input">
    <div id="statusPageSpinner" class="spinner-card" *ngIf="showSpinner">
        <app-spinner></app-spinner>
    </div>
    <div *ngIf="!showSpinner">
      <div class="credit-card-selection">
        <div *ngIf="showCardActionSuccess && !isCardDeclined" class="card-action-success">
          <app-icon class="top-align-margin" name="successAlert" color="success" labelA11y="Successful update">
          </app-icon>
          <div class="success">
            <b>{{cardActionSuccessMessage}}</b>
          </div>
        </div>
        <div *ngIf="showCardOptions; else noSelectedCard" class="selected-card">
          <div class="card-selection-radio-button">
            <h3 class="sco-heading sco--level3 sco--regular header">
              Select a credit/debit card to use
            </h3>

            <div *ngFor="let card of cardOptions; index as i" id="MAP_radio_cardSelect">
              <div *ngIf="showCardSelect(card)" class="align-items center">
                <div class="radio-choice mobile">
                  <div class="align-items start" (click)="selectCard(card)" (keyup.enter)="selectCard(card)" tabindex="-1">
                    <input class="sco-radio-button top-align-margin" type="radio"
                           name="Select this card for payment" role="radio" id="card_select_{{i}}"
                           [value]="getInstrumentIdCode(selectedCard) === getInstrumentIdCode(card)  ? 'selected' : 'unselected'"
                           [checked]="getInstrumentIdCode(selectedCard) === getInstrumentIdCode(card)"
                           tabindex="0">
                    <div class="label">
                      <label class="choice-selection sco-label" for="card_select_{{i}}">
                        <div class="card-display">
                          {{getCardSelectionLabel(card)}}
                          <app-cc-icon [cardBrand]="card.instrument.cardBrand"></app-cc-icon>
                          {{getCardDisplay(card)}}</div>
                      </label>
                    </div>
                  </div>
                  <div *ngIf="isSavedCardSelected(card)" class="edit-links-container">
                    <div class="edit-links">
                      <a role="link" name="Edit this card" [routerLink]="[]" id="MAP_cardSelect_edit"
                         (click)="toggleCardAction(cardActions.EDIT)">
                        <app-icon name="edit" color="active" labelA11y="Edit this card">
                        </app-icon>
                        Edit
                      </a>
                      <a role="link" name="Delete this card" [routerLink]="[]"
                         id="MAP_cardSelect_delete" (click)="toggleCardAction(cardActions.DELETE)">
                        <app-icon name="trash" color="active" labelA11y="Delete this card">
                        </app-icon>
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="isSavedCardSelected(card) && showCardActionConfirmation" class="edit-links-actions">
                <div *ngIf="displayEditIFrameApp" class="mini-app">
                  <div class="spinner-card mini-app" *ngIf="!iFrameUrl">
                    <app-spinner></app-spinner>
                  </div>
                  <div *ngIf="iFrameUrl">
                    <div class="edit-title radio-indent">
                      <b>Update card information</b>
                    </div>
                    <iframe *ngIf="iFrameUrl" [src]="iFrameUrl | safe: 'resourceUrl'" frameborder="0"
                            id="CCIframe" class="credit-card-iframe radio" [ngClass]="{'zipcode-height': isCCZipCodeEnabled}" title="Credit card app"
                            aria-label="Credit card app"></iframe>
                  </div>

                </div>
                <div class="confirm-delete-container radio-indent" *ngIf="displayConfirmDelete">
                  <div class="confirm-delete-text">
                    <app-icon name="warning" color="warning" labelA11y="Confirm card deletion"
                              size="16"></app-icon>
                    <span>Are you sure you want to delete your saved credit card?</span>
                  </div>
                  <div class="sco-button-group confirm-delete-button-group">
                    <button class="sco-button sco--outline" (click)="toggleCardAction()"
                            name="Cancel card deletion" role="button"
                            id="MAP_ccCancelDelete">
                      Cancel
                    </button>
                    <button class="sco-button sco--fill" (click)="deleteSavedCard()" name="Delete card"
                            role="button" id="MAP_ccConfirmDelete">
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="showCardSelect()" class="new-card-select">
              <div class="radio-choice align-items start" id="MAP_radio_newCardSelect" (click)="useNewCard()" (keyup.enter)="useNewCard()" tabindex="-1">
                <input class="sco-radio-button top-align-margin" type="radio"
                       name="Enter a new card for payment" role="radio" id="new_cardSelect"
                       [value]="isNewCardSelected  ? 'selected' : 'unselected'" [checked]="isNewCardSelected"
                       tabindex="0">
                <span class="label-container">
                  <div class="label">
                    <label class="choice-selection sco-label" for="new_cardSelect">
                      <div class="card-display">
                        Another credit/debit card
                        <span *ngIf="saveCode"> (for a one-time payment only)</span>
                      </div>
                    </label>
                  </div>
                </span>
              </div>
              <div *ngIf="showIFrameApp && !selectedCard">
                <div class="spinner-card mini-app" *ngIf="!iFrameUrl">
                  <app-spinner></app-spinner>
                </div>
                <div *ngIf="iFrameUrl">
                  <div *ngIf="isCCProfileEnabled  && !isGuestPayment">
                    <div *ngIf="!savedInstrumentIdCode && showCAMControls"
                         class="save-card-container radio-indent">
                      <div class="save-checkbox-container">
                        <input class="save-checkbox" id="save-card-checkbox" type="checkbox"
                               [checked]="saveCard" name="Select to save this card for future use"
                               [value]="saveCard ? 'selected' : 'unselected'" role="checkbox"
                               [attr.aria-checked]="saveCard" (change)="toggleSaveCard()" />
                      </div>
                      <div class="save-label-container">
                        <label for="save-card-checkbox">
                          <h3 class="save-card-title">Save this card</h3>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="isGuestPayment" class="cam-input-controls">
                    <div class="disclaimer-agreement-text">
                      {{disclaimerAgreementText}}
                    </div>
                  </div>

                  <iframe [src]="iFrameUrl | safe: 'resourceUrl'" frameborder="0" id="CCIframe"
                          class="credit-card-iframe radio" [ngClass]="{'pwol': isGuestPayment, 'zipcode-height': isCCZipCodeEnabled}" title="Credit card app"
                          aria-label="Credit card app"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #noSelectedCard>
        <div *ngIf="showIFrameApp">
          <div class="spinner-card mini-app" *ngIf="!iFrameUrl">
            <app-spinner></app-spinner>
          </div>
          <div *ngIf="iFrameUrl">
            <h3 class="sco-heading sco--level3 sco--regular header margin-bottom-large" [ngClass]="{'rcc': isRCC}">
              Enter your credit/debit card information
            </h3>
            <div *ngIf="showSaveCard()">
              <div class="save-card-container">
                <div class="save-checkbox-container">
                  <input class="save-checkbox" id="save-card-checkbox" type="checkbox"
                         [checked]="saveCard" name="Select to save this card for future use"
                         [value]="saveCard ? 'selected' : 'unselected'" role="checkbox"
                         [attr.aria-checked]="saveCard" (change)="toggleSaveCard()" />
                </div>
                <div class="save-label-container">
                  <label for="save-card-checkbox">
                    <h3 class="save-card-title">Save this card</h3>
                  </label>
                </div>
              </div>
            </div>
            <div *ngIf="isGuestPayment" class="cam-input-controls">
              <div class="disclaimer-agreement-text">
                {{disclaimerAgreementText}}
              </div>
            </div>

            <iframe [src]="iFrameUrl | safe: 'resourceUrl'" frameborder="0" id="CCIframe"
                    class="credit-card-iframe" [ngClass]="{'pwol': isGuestPayment, 'zipcode-height': isCCZipCodeEnabled, 'rcc': isRCC}" title="Credit card app"
                    aria-label="Credit card app"></iframe>
          </div>
        </div>
      </ng-template>

      <div *ngIf="showCAMControls" class="cam-input-controls"
           [ngClass]="{'sco-button-group nav-buttons': showCardOptions && !showIFrameApp, 'pwol': isGuestPayment}">
        <button class="sco-button sco--outline cancel-button"
                [ngClass]="{'iframe-position': showIFrameApp && !isRCC, 'disclaimer-position': showIFrameApp && showSubmitDisclaimer, 'rcc-position': isRCC}"
                (click)="cancelCC()" name="Cancel changes" role="button" [id]="backButtonId">
          {{backButtonText}}
        </button>
        <button *ngIf="showCardOptions && !showIFrameApp" class="sco-button sco--fill" [disabled]="!selectedCard"
                (click)="continueCC(selectedCard)" name="Continue to review payment details" role="button"
                id="MAP_ccContinue">
          {{nextButtonText}}
        </button>
        <div *ngIf="showSubmitDisclaimer" class="disclaimer-container">
          <div class="disclaimer-text">
            This step processes your card information. You'll review your payment next.
          </div>
        </div>
        <div class="rcc-authorization-message-container" *ngIf="isRCC">
          <div class="authorization-title">By selecting Submit, you agree that you:</div>
          <ul>
              <li>are the owner of the above account, and that the information provided is true and accurate</li>
              <li>have read and agree to these <span class="authorization-link" (click)="openCCAuthorizationModal()" (keyup.enter)="openCCAuthorizationModal()" role="link" name="View terms and conditions" tabindex="0">Terms and Conditions</span></li>
              <li>understand your account will be automatically charged on a recurring basis, according to how often you pay</li>
          </ul>
      </div>
      </div>
    </div>
</div>
