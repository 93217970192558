import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from 'node_modules/@angular/router';
import { Location, DOCUMENT } from '@angular/common';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { forkJoin } from 'rxjs';
import { datadogRum } from '@datadog/browser-rum'

import { environment } from 'src/environments/environment';

import { ComponentDisplay as cd } from 'src/app/utilities/constants/componentDisplay';
import CAMStorage from './utilities/constants/CAMStorage';
import Endpoints from 'src/app/utilities/constants/endpoints';

import { Alert } from './utilities/models/alert';
import { Features } from 'src/app/utilities/models/features';
import { WebAnalytics } from './utilities/models/webAnalytics';
import Billing from './utilities/models/billing';
import EsignSessionPolicy from './utilities/models/esignSessionPolicy';
import Footer from 'src/app/utilities/models/footer';
import Policy from './utilities/models/policy';

import { AccountService } from './utilities/services/account-service/account.service';
import { AnalyticsService } from './utilities/services/analytics-service/analytics.service';
import { BillingService } from 'src/app/utilities/services/billing-service/billing.service';
import { ChatService } from './utilities/services/chat-service/chat.service';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { Cookies } from 'src/app/utilities/services/cookies-service/cookies.service';
import { EsignService } from './utilities/services/esign-service/esign.service';
import { ModalService } from 'src/app/utilities/services/modal-service/modal.service';
import { PaperlessService } from 'src/app/utilities/services/paperless-service/paperless.service';
import { PolicyService } from 'src/app/utilities/services/policy-service/policy.service';
import { PreferencesService } from './utilities/services/preferences-service/preferences.service';
import { RoutingService } from 'src/app/utilities/services/routing-service/routing.service';
import { SlackService } from './utilities/services/slack-service/slack.service';
import { SMSService } from './utilities/services/sms-service/sms.service';
import { UserActivityService } from 'src/app/utilities/services/user-activity-service/user-activity.service';

import { AuthUser } from './utilities/models/authUser';
import PolicyMethods from './utilities/methods/policy.methods';
import AnalyticsMethods from './utilities/methods/analytics.methods';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  angularRoutes: string[] = ['/homepage'];
  currentYear: number;
  displaySMSAgentCard: boolean = false;
  env = environment;
  faqLink: string = Endpoints.url.faq;
  features: Features = new Features();
  footer: Footer = null;
  headerAlerts: Alert[] = [];
  displayHeaderAlerts: Alert[] = [];
  greeting: string = '';
  greetingShort: string;
  helpActive: boolean;
  imgLoc: string = environment.imgLoc;
  isChatLoaded: boolean = false;
  isOmniChatLoaded: boolean = false;
  isMobileApp = false;
  isModalOpen = false;
  isOmniChatLaunched: boolean = false;
  paperlessTermsAndConditions: string;
  searchLaunched: boolean = false;
  trackDataLayerTransactionAnalytics: boolean = false;
  url = Endpoints.url;
  pingLogout = Endpoints.url.logoutPing;
  webAnalytics: WebAnalytics[] = [];
  idleTimerFlag: boolean = false;
  logolinkauth: boolean = false;
  logolinksafecocom: string = this.url.safecocom;

  suppressAgentCard = false;
  suppressFooter = false;
  suppressHeader = false;
  suppressHeaderInfo = false;
  suppressNav = true;
  showHeaderAlerts = true;
  suppressBottomMargin = false;
  suppressTimer = false;
  suppressTopMargin = false;
  suppressReturningTag = true;

  showCookieMessage = false;
  cookieMessage = '';

  dlBilling: Billing[] = [];
  dlPolicies: Policy[] = [];
  dlRenewalPolicies: any[] = [];
  dlGold: boolean = false;
  dlEsign: EsignSessionPolicy[] = [];

  isAuthPage: boolean = false;
  loading: boolean = true;

  sdkLoaded: boolean = false;
  auth0UserNickname;

  suppressHeaderFlash: boolean = false; //needs more testing
  
  constructor(
    private billingService: BillingService,
    private commonService: CommonService,
    private cookies: Cookies,
    private location: Location,
    private modalService: ModalService,
    private paperlessService: PaperlessService,
    private policyService: PolicyService,
    private router: Router,
    private routingService: RoutingService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private esignService: EsignService,
    private chatService: ChatService,
    private userActivityService: UserActivityService,
    private smsService: SMSService,
    private preferencesService: PreferencesService,
    private accountService: AccountService,
    private slackService: SlackService,
    @Inject(DOCUMENT) private document: Document
  ) {
    router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => {
        let appTitle = '';
        let child = this.activatedRoute.firstChild;
        while (child.firstChild) {
          child = child.firstChild;
        }
        if (child.snapshot.data['title']) {
          return child.snapshot.data['title'];
        }
        return appTitle;
      })
    ).subscribe((title: string) => {
      this.titleService.setTitle(title + ' | Safeco');
      this.suppressComponent(this.location.path());
    });
  }

  async ngOnInit() {
    const headerFlash = this.cookies.getHeaderFlashCookie();

    //if (headerFlash) {
    if (headerFlash !== null) {
      this.suppressHeaderFlash = true;
      this.cookies.clearCookie('HeaderFlash');
    }

    this.setAppContext();
    this.suppressComponent(this.location.path());
    
    await this.getFeatures();
    this.initDatadogRUM();
    await this.setAnalyticsScript();
    this.reportMMAPartyId();
    await this.getUserProfile();

    this.commonService.getCamFooter();

    if (!this.commonService.areCookiesEnabled() && ((~this.location.path().indexOf('login')) || (~this.location.path().indexOf('create')))) {
      this.setCookieMessage(this.location.path());
      this.showCookieMessage = true;
      this.suppressBottomMargin = false;
    } else {
      const url = window.location.href;
      const mock = ~url.indexOf('?mock=') ? url.split('?mock=')[1] : '';
      if (mock) {
        if (mock === 'clear') {
          sessionStorage.removeItem('mock');
        } else {
          sessionStorage.setItem('mock', mock);
        }
      }

      // Call services if authenticated path
      if (!this.commonService.supress(cd.supress.unauth, this.location.path())) {
        this.isAuthPage = true;
        this.logolinkauth = true;
        this.idleTimerFlag = true;

        this.searchLaunched = this.features.searchLaunched;

        //Post login process then call functions that get policies and billing
        try {
          // Temporary fix - wait for the new update access token with policy numbers on it
          // application was attaching the token before the post login processing finished
          setTimeout(function() {
          }, 30000);
          /* this didn't work -
            await this.auth.getAccessTokenSilently({
            ignoreCache: true
          });*/
        } catch (error) {
          console.log(error);
        } finally {
          // this.commonService.loadScript('assets/scripts/googleTag_loginEvent.js'); will revisit after tealium injects scripts
          this.getPreferences();
          this.policyService.getPolicies();
          this.billingService.getBilling();
          this.checkChatInitialization();
          this.smsService.initializeSMSDisplay(this.location.path());
          //this.setApplicationMessage();
        }
      }
      // Initialize Slack Service to accomodate slack feedback post login process and Payment without login process
      this.slackService.initializeSlackService();

      this.loading = false;
      this.sendAnalyticsOnLoad();
      this.recordUserSession();

      this.router.events.pipe(
        filter(event => event instanceof NavigationStart)
      ).subscribe(async (event: NavigationStart) => {
        this.handleMAPStorage(event.url);
        this.smsService.setSMSLinkDisplay(event.url);
      });

      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.commonService.setADATitleFocus();
        this.sendAnalytics(this.location.path());
        this.recordUserSession();
        this.checkChatInitialization();
      });

      this.currentYear = new Date().getFullYear();
      this.helpActive = false;

      this.getPaperlessTermsAndConditions();
      this.modalService.isModalOpenObs.subscribe(
        (response) => { 
          // if trying to open modal that is paperless - special handling to make sure Paperless T&Cs are loaded
          if(response.openModal && response.isModalPaperless) {
            // if T&Cs are loaded open, otherwise don't open Paperless Modal
            this.isModalOpen = this.paperlessTermsAndConditions ? true : false;
          } else { // not Paperless type modal
            this.isModalOpen = response.openModal;
          }
        }
      );

      this.smsService.displaySMSObs.subscribe(
        displaySMS => { this.displaySMSAgentCard = displaySMS; }
      );

      this.analyticsService.dataLayerTransactionObs.subscribe(
        $event => { 
          if ($event) {
            this.trackDataLayerTransactionAnalytics = true;
            this.sendAnalytics(this.location.path());
          }
        }
      )
    }
  }

  checkChatInitialization(): void {

    if(this.isOmniChatLaunched) {
      if(!this.isOmniChatLoaded && this.isAuthPage) {
        this.chatService.initiateOmniChat();
        this.isOmniChatLoaded = true;
      }
    } else {
      if (!this.isChatLoaded && this.isAuthPage) {
        const path = location.pathname;
        
        if (path && path.toLowerCase() !== "homepage") {
          this.chatService.initialize();
          this.isChatLoaded = true;
        }
      }
    }
  }
  
  initDatadogRUM() {
    if (this.features && this.features.datadogRUMLaunched && this.env.datadogEnv) {
      const user = this.accountService.getAuthUser();

      datadogRum.setGlobalContextProperty('troux_uuid', "1BF02DB4-5111-45E9-AD38-52B30D7F2847");

      datadogRum.init({
        applicationId: '8b57158e-8f3f-4434-9d56-5b722cf30bf7',
        clientToken: 'pub494b96c11e6386bda6ca7fe014fcb44e',
        site: 'datadoghq.com',
        env: this.env.datadogEnv,
        service: 'safeco-mma',
        sessionSampleRate: 100, // percentage of sessions being monitored
        sessionReplaySampleRate: 0, // percentage of sessions being recorded in session replay
        trackResources: true, // Enables collection of resource events.
        trackLongTasks: true, // Enables collection of long task events.
        trackUserInteractions: false, // Enables automatic collection of users actions.
        defaultPrivacyLevel: 'mask', // Session replay privacy option,
        allowedTracingUrls: [ environment.datadogAllowedTraceUrls ]

        // Adding for future consideration but can be turned off for now because 'trackUserInteractions' is turned off
        // beforeSend: ((event, context) => this.analyticsService.configureDatadogActions(event, context)) // manage and manipulate events before sending to DD
      });

      // custom RUM action
      if (user && user.userId) {
        datadogRum.setUser({
          id: user.userId
        });
      }

      // For session replay recording
      // datadogRum.startSessionReplayRecording();
    }
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimer(event) {
    // reset idle time based on activity
    if (event.target.id !== 'logoutLink') {
      this.userActivityService.notifyUserAction();
    }
  }

  /*@HostListener('window:beforeunload', ['$event'])
  async beforeunloadHandler(event: any) {
    const isUserLoggingOut = CAMStorage.getItemAsBool(CAMStorage.storageKeys.isUserLoggingOut);

    if (isUserLoggingOut) {
      CAMStorage.removeItemInStorage(CAMStorage.storageKeys.isUserLoggingOut);
    } else {
      await this.updateUserPreferences();
    }
  }*/

  async initializeCustomer(): Promise<void> {
    try {
      return new Promise((resolve) => {
        forkJoin([
          this.getFeatures(),
          this.getUserProfile()
        ]).subscribe(() => {
          resolve();
        });
      });
    } catch (err) {
      console.log(err);
    }
  }

  async setApplicationMessage(): Promise<void> {
    this.headerAlerts = [];
  }

  setDisplayHeaderAlerts(): void {
    let showHeaderAlerts: boolean = false;

    cd.display.headerAlerts.forEach((alerts: { url: string, heapIds: string[] }) => {
      const location: string = this.location.path();

      if (~location.indexOf(alerts.url)) {
        this.displayHeaderAlerts = this.headerAlerts.filter((headerAlert: Alert) => alerts.heapIds.some((heapId: string) => ~headerAlert.heapId.indexOf(heapId)));

        showHeaderAlerts = true;
      }
    });

    this.showHeaderAlerts = showHeaderAlerts;
  }

  async sendAnalyticsOnLoad(): Promise<void> {
    // Setting dataLayer analytics once on initial app load
    // this.sendAnalytics will be called through NavigationEnd on subsequent navigations
    const authUser: AuthUser = this.accountService.getAuthUser();

    if (authUser) {
      this.trackUserExperienceAnalytics();
      await this.sendAnalytics(this.location.path());
      this.trackAnalyticsId(authUser.userId);

      // only track State Auto logins once
      this.trackStateAutoAnalytics();
    } else {
      const isAccountAnalyticsRefactorLaunched = this.features?.accountAnalyticsRefactorLaunched;
      this.analyticsService.trackUnauthTealiumView(isAccountAnalyticsRefactorLaunched); // unauth pages' analytics
    }
  }

  trackAnalyticsId(userId: string): void {
    const isAnalyticsIdSetKey: string = CAMStorage.storageKeys.isAnalyticsIdSet;
    const isAnalyticsIdSet: boolean = CAMStorage.getItemAsBool(isAnalyticsIdSetKey);

    if (!isAnalyticsIdSet && userId) {
      this.analyticsService.trackHeapIdentity(userId);
      CAMStorage.setItemInStorage(isAnalyticsIdSetKey, true)
    }
  }

  async sendAnalytics(url: string) {
    if (!this.commonService.supress(cd.supress.unauth, url)) {
      // track immediately if there's no transaction being tracked on page view, or wait until notified that we can track a transaction page
      if (!AnalyticsMethods.isDataLayerTransactionPage(url) || this.trackDataLayerTransactionAnalytics) {
        this.trackDataLayerTransactionAnalytics = false;
        this.billingService.getBilling().then((billingResult) => {
          this.dlBilling = billingResult;
        });
        const authUser = this.accountService.getAuthUser();
        await this.policyService.getPolicies().then((policyResult) => {
          this.dlPolicies = policyResult;
          this.policyService.getRenewalPolicies().then((renewalResult) => { this.dlRenewalPolicies = renewalResult; });
          this.policyService.isGoldPlusOrGoldAgency().then((goldResult) => this.dlGold = goldResult);
  
          this.esignService.getAllEsignPolicies(this.dlPolicies).then((esignResult) => {
            this.dlEsign = esignResult;
            setTimeout(() => {
              this.analyticsService.callAnalytics(this.dlPolicies, this.dlBilling, this.dlRenewalPolicies, authUser, this.dlGold, this.dlEsign);
            });
          });
        });
      }
    } else {
      setTimeout(() => {
        const isAccountAnalyticsRefactorLaunched = this.features?.accountAnalyticsRefactorLaunched;
        this.analyticsService.trackUnauthTealiumView(isAccountAnalyticsRefactorLaunched); // unauth pages' analytics
      });
    }
  }

  async trackUserExperienceAnalytics() {
    try {
      await this.getPreferences();
      this.analyticsService.setUserExperienceAnalytics();
    } catch (err) {
      console.log(err);
    }
  }

  trackStateAutoAnalytics() {
    if (this.features && this.features.stateAutoLaunched) {
      if (this.dlPolicies && this.dlPolicies.length && PolicyMethods.isAnyPolicyTransferredFromStateAuto(this.dlPolicies)) {
        this.analyticsService.trackAnalytics("MMA_login_StateAuto");
      }
    }
  }

  suppressComponent(url: string) {
    if (url != '') {
      this.suppressNav = this.commonService.supress(cd.supress.navigation, url);
      this.suppressAgentCard = this.commonService.supress(cd.supress.agentCard, url);
      this.suppressHeader = (this.commonService.supress(cd.supress.margins, url) || this.isMobileApp || this.suppressHeaderFlash); // || this.suppressHeaderFlash
      this.suppressHeaderInfo = (this.commonService.supress(cd.supress.headerInfo, url));
      this.suppressFooter = this.commonService.supress(cd.supress.margins, url);
      this.suppressBottomMargin = this.commonService.supress(cd.supress.headerBottomMargin, url);
      this.suppressTimer = (this.commonService.supress(cd.supress.idleTimer, url));
      this.suppressTopMargin = this.commonService.supress(cd.supress.footerTopMargin, url);
      this.suppressReturningTag = this.commonService.supress(cd.supress.unauth, url);
      this.suppressHeaderFlash = false;
    }
  }

  setCookieMessage(url: string) {
    this.cookieMessage = 'Update your browser settings to allow cookies. ';
    if (~url.indexOf('login')) {
      this.cookieMessage += 'Then refresh your browser to log in.';
    } else {
      this.cookieMessage += 'Then refresh your browser to create your account.';
    }
  }

  handleMAPStorage(url: string) {
    // Make a payment check for paymentStorage or redirect to first page
    if (~url.indexOf('/billing/payment/')) {
      const paymentStorage = CAMStorage.getItemInStorage(CAMStorage.storageKeys.billingPaymentStorage, true);
      if (!paymentStorage) {
        this.routingService.routeTo(this.url.payment);
      }
    }
    // Make a payment clear session storage when navigate away
    if (!~url.indexOf('/billing/payment')) {
      CAMStorage.removeItemInStorage(CAMStorage.storageKeys.billingPaymentStorage);
      CAMStorage.removeItemInStorage(CAMStorage.storageKeys.selectPaymentPolicyNumber);
    }
  }

  setAppContext() {
    this.isMobileApp = this.commonService.isMobileApp();
    if (this.isMobileApp) {
      this.document.body.classList.add('state-app');
    }
  }

  async getFeatures(): Promise<void> {
    try {
      this.features = await this.commonService.getFeatures();
      
      if (this.features) {
        this.isOmniChatLaunched = this.features.omniChatLaunched
      }      
    } catch (error) {
      console.log(error);
    }
  }

  async getUserProfile() {
    try {
      const currentUser: AuthUser = this.accountService.getAuthUser();
      if (currentUser) {
        this.greeting = currentUser.name;
        this.greetingShort = 'Hello ' + currentUser.name; // match the greeting on the mobile view header
      }
      return currentUser;
      
    } catch (error) {
      console.log(error);
    }
  }

  async getPreferences() {
    try {
      await this.preferencesService.getPreferences();
    } catch (error) {
      console.log('could not get preferences: ' + error);
    }

  }

  async getPaperlessTermsAndConditions() {
      try {
        const paperlessTermsContent = await this.paperlessService.getPaperlessTermsAndConditions();
        this.paperlessTermsAndConditions = paperlessTermsContent;
      } catch (error) {
        console.log(error);
      }
  }

  onKeyPress(event) {
    const targetId = event.target.id;
    if (event.key === 'Enter') {
      if (targetId === 'logoutLink') {
        this.accountService.logout();
      }
      if (targetId === 'helpLink' || targetId === 'closeHelp') {
        this.toggleHelpMenu();
      }
    }
  }

  toggleHelpMenu() {
    this.helpActive = !this.helpActive;
    //for setting focus to the top of the help menu when
    //it's opened, sets focus back to the help link when closed
    const helpMenu: HTMLElement = this.document.getElementById('helpMenu');
    const helpLink: HTMLElement = this.document.getElementById('helpLink');
    if (this.helpActive) {
      helpMenu.focus();
    }
    else {
      helpLink.focus();
    }
  }

  // if no cookie, we have not gone through log in process
  // using same check that's in common service loginProcessing
  // boolean for running return customer tag in the html once after login
  needToRunLoginProcess(): boolean {
    const loginCookie = this.cookies.getMMALoginCookie();
    return loginCookie === '';
  }

  async setAnalyticsScript(): Promise<void> {
    const TEALIUM_SCRIPT_NAME: string = "Tealium";
    let scriptName: string = TEALIUM_SCRIPT_NAME;
    let analyticsScript: string = this.env.tealiumScript;

    if (analyticsScript) {
      try {
        await this.commonService.loadScript(analyticsScript);
      } catch (err) {
        let trackingID: string = `MMA_Script_LoadFailure|${scriptName}`;

        this.analyticsService.trackAnalytics(trackingID);
      } finally {
        console.log('done');
      }
    }
  }

  reportMMAPartyId(): void {
    const authUser = this.accountService.getAuthUser();

    if ((authUser && authUser.masterPartyId)) {
      const partyIdAnalytics: {failSafe: boolean, mmaPartyID: string } = {
        failSafe: false,
        mmaPartyID: authUser.masterPartyId
      };

      this.analyticsService.trackAnalyticsProperties('MMAPartyId', partyIdAnalytics);
    }
  }

  recordUserSession(): void {
    if (this.isAuthPage && (<any>window)) {
      const cs = (<any>window)._uxa || [];
      const authUser = this.accountService.getAuthUser();

      if (authUser) {
        cs.push(['trackPageEvent', `@user-identifier@${authUser.userId}`]);
      }
    }
  }

}
