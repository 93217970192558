import Endpoints from 'src/app/utilities/constants/endpoints';
import { PAYMENT_STATUS_CODES } from 'src/app/utilities/constants/payment';
import CAMStorage from 'src/app/utilities/constants/CAMStorage';
import { Alert, AlertType } from 'src/app/utilities/models/alert';
import { BillingPaymentAccount, BillingPaymentAccountResponse, PaymentStorage } from 'src/app/utilities/models/billingPaymentAccount';
import { CreditCardInfo } from 'src/app/utilities/models/creditCard';
import EFTInfo from 'src/app/utilities/models/eftInfo';
import {
  AccountsWithAmountToPay,
  BankWithdrawalPaymentResponse,
  BankWithdrawalPaymentsRequest,
  BankWithdrawalPaymentsResponse,
  CreditCardPaymentResponse,
  CreditCardPaymentsRequest,
  CreditCardPaymentsResponse
} from 'src/app/utilities/models/paymentInfo';

import DateMethods from 'src/app/utilities/methods/date.methods';

export default class PayMethods {

  public static createCreditCardPaymentsRequest(paymentStorage: PaymentStorage): CreditCardPaymentsRequest {
    let creditCardPaymentsReq: CreditCardPaymentsRequest = new CreditCardPaymentsRequest();

    creditCardPaymentsReq.contextGuid = paymentStorage.contextGuid;
    creditCardPaymentsReq.requestGuid = paymentStorage.requestGuid;
    creditCardPaymentsReq.accountsWithAmountToPay = this.configureAccountsWithAmountToPay(paymentStorage.selectedAccounts);
    creditCardPaymentsReq = this.configureCreditCardInfoForRequest(creditCardPaymentsReq, paymentStorage.creditCardInfo);

    return creditCardPaymentsReq;
  }

  public static createBankWithdrawalPaymentsRequest(paymentStorage: PaymentStorage): BankWithdrawalPaymentsRequest {
    let bankWithdrawalPaymentsReq: BankWithdrawalPaymentsRequest = new BankWithdrawalPaymentsRequest();

    bankWithdrawalPaymentsReq.contextGuid = paymentStorage.contextGuid;
    bankWithdrawalPaymentsReq.requestGuid = paymentStorage.requestGuid;
    bankWithdrawalPaymentsReq.eftAccountsWithAmountToPay = this.configureAccountsWithAmountToPay(paymentStorage.selectedAccounts);
    bankWithdrawalPaymentsReq = this.configureBankWithdrawalInfoForRequest(bankWithdrawalPaymentsReq, paymentStorage.eftInfo);

    return bankWithdrawalPaymentsReq;
  }  

  public static getPolicyNumberForRequest(paymentStorage: PaymentStorage): string {
    const accountWithPolicyNumber: BillingPaymentAccount = paymentStorage.selectedAccounts.find((account: BillingPaymentAccount) => !!(account.policyNumber));
    const policyNumber = accountWithPolicyNumber ? accountWithPolicyNumber.policyNumber : '';

    return policyNumber;
  }

  public static translateCreditCardPaymentsResponse(paymentStorage: PaymentStorage, creditCardPayments: CreditCardPaymentsResponse): PaymentStorage {
    paymentStorage.paymentResponse.statusCode = creditCardPayments.statusCode;
    paymentStorage.paymentResponse.statusMessage = creditCardPayments.statusMessage;

    paymentStorage.selectedAccounts.forEach((selectedAccount: BillingPaymentAccount) => {
      if (creditCardPayments && creditCardPayments.creditCardMultipleAccountsResults && creditCardPayments.creditCardMultipleAccountsResults.length) {
        const creditCardPayment: CreditCardPaymentResponse = this.getCreditCardPaymentForSelectedAccount(selectedAccount, creditCardPayments.creditCardMultipleAccountsResults);
        const billingPaymentAccountResponse: BillingPaymentAccountResponse = new BillingPaymentAccountResponse();

        billingPaymentAccountResponse.statusCode = creditCardPayment.statusCode;
        billingPaymentAccountResponse.statusMessage = creditCardPayment.statusMessage;
        billingPaymentAccountResponse.paymentConfirmationNumber = creditCardPayment.paymentConfirmationNumber;

        if (!paymentStorage.paymentResponse.paymentDate && !DateMethods.isDateMinValue(creditCardPayment.datePosted)) {
          paymentStorage.paymentResponse.paymentDate = creditCardPayment.datePosted;
        }

        selectedAccount.paymentResponse = billingPaymentAccountResponse;
        selectedAccount.paymentConfirmationNumber = selectedAccount.paymentResponse.paymentConfirmationNumber;
      }
    });

    paymentStorage.selectedAccounts.sort((a, b) => this.sortPaymentAccountsByStatusCodeDesc(a.paymentResponse, b.paymentResponse));

    return paymentStorage;
  }

  public static translateBankWithdrawalPaymentsResponse(paymentStorage: PaymentStorage, bankWithdrawalPayments: BankWithdrawalPaymentsResponse): PaymentStorage {
    paymentStorage.paymentResponse.statusCode = bankWithdrawalPayments.statusCode;
    paymentStorage.paymentResponse.statusMessage = bankWithdrawalPayments.statusMessage;

    paymentStorage.selectedAccounts.forEach((selectedAccount: BillingPaymentAccount) => {
      if (bankWithdrawalPayments && bankWithdrawalPayments.eftMultipleAccountsResult && bankWithdrawalPayments.eftMultipleAccountsResult.length) {
        const bankWithdrawalPayment: BankWithdrawalPaymentResponse = this.getBankWithdrawalPaymentForSelectedAccount(selectedAccount, bankWithdrawalPayments.eftMultipleAccountsResult);
        const billingPaymentAccountResponse: BillingPaymentAccountResponse = new BillingPaymentAccountResponse();

        if (bankWithdrawalPayment) {
          billingPaymentAccountResponse.statusCode = bankWithdrawalPayment.statusCode;
          billingPaymentAccountResponse.statusMessage = bankWithdrawalPayment.statusMessage;
          billingPaymentAccountResponse.paymentConfirmationNumber = bankWithdrawalPayment.confirmationNumber;

          if (!paymentStorage.paymentResponse.paymentDate && !DateMethods.isDateMinValue(bankWithdrawalPayment.paymentDate)) {
            paymentStorage.paymentResponse.paymentDate = bankWithdrawalPayment.paymentDate;
          }
  
          if (!paymentStorage.paymentResponse.withdrawalDate && !DateMethods.isDateMinValue(bankWithdrawalPayment.withdrawalDate)) {
            paymentStorage.paymentResponse.withdrawalDate = bankWithdrawalPayment.withdrawalDate;
          }

          if (bankWithdrawalPayment.statusCode === PAYMENT_STATUS_CODES.successful) {
            selectedAccount.paymentConfirmationNumber = bankWithdrawalPayment.confirmationNumber;
          }

          if (bankWithdrawalPayment.statusCode === PAYMENT_STATUS_CODES.duplicatePayment) {
            selectedAccount.displayDuplicatePaymentError = true;
          }

          selectedAccount.accountMessage = billingPaymentAccountResponse.statusMessage;
        } else {
          billingPaymentAccountResponse.statusCode = PAYMENT_STATUS_CODES.systemError;
        }

        selectedAccount.paymentResponse = billingPaymentAccountResponse;
      }
    });

    paymentStorage.selectedAccounts.sort((a, b) => this.sortPaymentAccountsByStatusCodeDesc(a.paymentResponse, b.paymentResponse));

    return paymentStorage;
  }

  public static shouldHaltNavigationToConfirmation(statusCode: number): boolean {
    switch (statusCode) {
      case PAYMENT_STATUS_CODES.successful:
      case PAYMENT_STATUS_CODES.hasAtLeastOneError:
        return false;
      default:
        return true;
    }
  }

  public static createErrorAlertPostPaymentSubmit(responseStatusCode: number): Alert {
    const alert: Alert = new Alert(AlertType.NEGATIVE);
    const heapIDStart: string = 'MMA-View-NotificationsPayment|Review|';
    const linkHeapIDStart: string = 'PaymentReview_acts_link_error_';

    switch (responseStatusCode) {
      case PAYMENT_STATUS_CODES.invalidRoutingNumber:
        alert.messages = [`The routing number is not valid. Please { "text" : "review your information", "url" : "${Endpoints.url.paymentMethod}", "heapId" : "${linkHeapIDStart}payment_method" } and try again`];
        alert.heapId = `${heapIDStart}InvalidRoutingNumber`;
        this.partialClearStoredBankWithdrawal();
        break;
      case PAYMENT_STATUS_CODES.invalidBankAccountNumber:
        alert.messages = [`The account number is not valid. Please { "text" : "review your information", "url" : "${Endpoints.url.paymentMethod}", "heapId" : "${linkHeapIDStart}payment_method" } and try again`];
        alert.heapId = `${heapIDStart}InvalidBankAccountNumber`;
        this.partialClearStoredBankWithdrawal();
        break;
      case PAYMENT_STATUS_CODES.cardOverLimit:
        alert.messages = [`Your card has been declined. Please { "text" : "correct any errors or enter new card information", "url" : "${Endpoints.url.paymentMethod}", "heapId" : "${linkHeapIDStart}payment_method" }. 
          If you have questions or issues with your card, please contact your credit card company / issuing bank directly.`];
        alert.heapId = `${heapIDStart}CreditCardDeclined`;
        this.partialClearStoredCreditCard();
        break;
      case PAYMENT_STATUS_CODES.expiredCard:
        alert.messages = [`Requested payment date exceeds the credit card’s expiration date. Please { "text" : "review your card information", "url" : "${Endpoints.url.paymentMethod}", "heapId" : "${linkHeapIDStart}payment_method" } and try again`];
        alert.heapId = `${heapIDStart}CreditCardExpired`;
        this.partialClearStoredCreditCard();
        break;
      case PAYMENT_STATUS_CODES.duplicatePayment:
        alert.messages = [`We have already received a payment with this amount. See below.`];
        alert.heapId = `${heapIDStart}DuplicatePayment`;
        this.setDeleteStorageOnRestartFlag();
        break;
      default:
        alert.messages = [`There was a problem processing your payment request. Please { "text" : "select another payment method", "url" : "${Endpoints.url.payment}", "heapId" : "${linkHeapIDStart}select" } or try again later. 
          If the problem persists, please contact us at 1-888-458-2246 or contact your local Safeco agent for assistance.`];
        alert.heapId = `${heapIDStart}ProcessingError`;
        this.setDeleteStorageOnRestartFlag();

        break;
    }

    return alert;
  }

  private static setDeleteStorageOnRestartFlag(): void {
    const paymentStorage: PaymentStorage = CAMStorage.getItemInStorage(CAMStorage.storageKeys.billingPaymentStorage, true);

    paymentStorage.deleteStorageOnRestart = true;

    CAMStorage.setItemInStorage(CAMStorage.storageKeys.billingPaymentStorage, paymentStorage);
  }

  public static clearPaymentStorage(): void {
    const paymentStorage: PaymentStorage = new PaymentStorage();

    CAMStorage.setItemInStorage(CAMStorage.storageKeys.billingPaymentStorage, paymentStorage);
  }

  public static clearCreditCardInfoOnPaymentStorage(): void {
    const paymentStorage: PaymentStorage = CAMStorage.getItemInStorage(CAMStorage.storageKeys.billingPaymentStorage, true);

    if (paymentStorage && paymentStorage.creditCardInfo) {
      paymentStorage.creditCardInfo = new CreditCardInfo();
      paymentStorage.creditCardInfo.cardType = 'CC';
    }

    CAMStorage.setItemInStorage(CAMStorage.storageKeys.billingPaymentStorage, paymentStorage);
  }

  public static partialClearStoredCreditCard(): void {
    const paymentStorage: PaymentStorage = CAMStorage.getItemInStorage(CAMStorage.storageKeys.billingPaymentStorage, true);

    paymentStorage.pageEdit = true;

    if (paymentStorage && paymentStorage.creditCardInfo && !paymentStorage.creditCardInfo.ccProfileExists) {
      const firstName: string = paymentStorage.creditCardInfo.cardHolderFName;
      const middleName: string = paymentStorage.creditCardInfo.cardHolderMName;
      const lastName: string = paymentStorage.creditCardInfo.cardHolderLName;

      paymentStorage.creditCardInfo = new CreditCardInfo();
      paymentStorage.creditCardInfo.cardType = 'CC';
      paymentStorage.creditCardInfo.cardHolderFName = firstName;
      paymentStorage.creditCardInfo.cardHolderMName = middleName;
      paymentStorage.creditCardInfo.cardHolderLName = lastName;
      paymentStorage.creditCardInfo.saveAccount = false;
    }

    CAMStorage.setItemInStorage(CAMStorage.storageKeys.billingPaymentStorage, paymentStorage);
  }

  public static partialClearStoredBankWithdrawal(): void {
    const paymentStorage: PaymentStorage = CAMStorage.getItemInStorage(CAMStorage.storageKeys.billingPaymentStorage, true);

    if (paymentStorage && paymentStorage.eftInfo) {
      const nameOnBank: string = paymentStorage.eftInfo.nameOnBank;

      paymentStorage.eftInfo = new EFTInfo();
      paymentStorage.eftInfo.nameOnBank = nameOnBank;
    }

    CAMStorage.setItemInStorage(CAMStorage.storageKeys.billingPaymentStorage, paymentStorage);
  }

  private static configureCreditCardInfoForRequest(creditCardPaymentsReq: CreditCardPaymentsRequest, creditCardInfo: CreditCardInfo): CreditCardPaymentsRequest {
    if (creditCardInfo) {
      creditCardPaymentsReq.billingZipCode = creditCardInfo.billingZipCode;
      creditCardPaymentsReq.cardBrand = creditCardInfo.cardBrandAbbr;
      creditCardPaymentsReq.cardNumberMasked = creditCardInfo.cardNumberMasked;
      creditCardPaymentsReq.cardType = creditCardInfo.cardType;
      creditCardPaymentsReq.ccProfileExists = creditCardInfo.ccProfileExists;
      creditCardPaymentsReq.emailAddress = creditCardInfo.emailAddress;
      creditCardPaymentsReq.emailNotification = creditCardInfo.emailNotification;
      creditCardPaymentsReq.expirationMonth = creditCardInfo.expirationMonth;
      creditCardPaymentsReq.expirationYear = creditCardInfo.expirationYear;
      creditCardPaymentsReq.financialAccountId = creditCardInfo.financialAccountID;
      creditCardPaymentsReq.firstName = creditCardInfo.cardHolderFName || creditCardInfo.firstName;
      creditCardPaymentsReq.lastName = creditCardInfo.cardHolderLName || creditCardInfo.lastName;
      creditCardPaymentsReq.middleInitial = creditCardInfo.cardHolderMName || creditCardInfo.middleInitial;
      creditCardPaymentsReq.saveAccount = creditCardInfo.saveAccount;
    }

    return creditCardPaymentsReq;
  }

  private static configureBankWithdrawalInfoForRequest(bankWithdrawalPaymentsReq: BankWithdrawalPaymentsRequest, eftInfo: EFTInfo): BankWithdrawalPaymentsRequest {
    if (eftInfo) {
      bankWithdrawalPaymentsReq.bankAccountNumber = eftInfo.accountNumber;
      bankWithdrawalPaymentsReq.nameOnBankAccount = eftInfo.nameOnBank;
      bankWithdrawalPaymentsReq.routingNumber = eftInfo.routingNumber;
    }

    return bankWithdrawalPaymentsReq;
  }

  private static configureAccountsWithAmountToPay(selectedAccounts: BillingPaymentAccount[]): AccountsWithAmountToPay[] {
    const accountsWithAmountToPay: AccountsWithAmountToPay[] = [];

    if (selectedAccounts && selectedAccounts.length) {
      selectedAccounts.forEach((selectedAccount: BillingPaymentAccount) => {
        const accountToPay: AccountsWithAmountToPay = new AccountsWithAmountToPay();

        accountToPay.amountToPay = selectedAccount.amountToPayDisplay;
        accountToPay.billingAccountNumber = selectedAccount.billingAccountNumber;
        accountToPay.policyNumber = selectedAccount.policyNumber;

        accountsWithAmountToPay.push(accountToPay);
      });
    }

    return accountsWithAmountToPay;
  }

  private static getCreditCardPaymentForSelectedAccount(selectedAccount: BillingPaymentAccount, creditCardPayments: CreditCardPaymentResponse[]): CreditCardPaymentResponse {
    const creditCardPayment: CreditCardPaymentResponse = creditCardPayments.find((payment: CreditCardPaymentResponse) =>
      payment.billingAccountNumber === selectedAccount.billingAccountNumber
    );

    return creditCardPayment;
  }

  private static getBankWithdrawalPaymentForSelectedAccount(selectedAccount: BillingPaymentAccount, bankWithdrawalPayments: BankWithdrawalPaymentResponse[]): BankWithdrawalPaymentResponse {
    const bankWithdrawalPayment: BankWithdrawalPaymentResponse = bankWithdrawalPayments.find((payment: BankWithdrawalPaymentResponse) => {
      return (payment.tokenizedProcessingResult && payment.tokenizedProcessingResult.billingAccountNumber === selectedAccount.billingAccountNumber);
    });

    return bankWithdrawalPayment;
  }

  public static sortPaymentAccountsByStatusCodeDesc(responseA: any, responseB: any): number {
    // list errored accounts first
    if (responseA && responseB) {
      if (responseA.statusCode > responseB.statusCode) {
        return -1;
      } else {
        if (responseA.statusCode < responseB.statusCode) {
          return 1;
        } else {
          return 0;
        }
      }
    }
  }
}
