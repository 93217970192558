export const EFTAccountTypes: any = {
    checking: 'C',
    savings: 'S'
};

export const CreditCardType: any = {
    credit: 'CC',
    debit: 'DC',
    debitDD: 'DD'
};

export const PaymentMethods: any = {
    manual: '1',
    recurrCC: '4',
    recurrEFT: '2'
};

export function setPaymentTypeDescription(paymentType: string): string {
    let paymentTypeDescription: string = '';

    if (paymentType) {
        paymentType = paymentType.toUpperCase();

        switch (paymentType) {
            case EFTAccountTypes.checking:
                paymentTypeDescription = 'Checking';
                break;
            case EFTAccountTypes.savings:
                paymentTypeDescription = 'Savings';
                break;
        }
    }

    return paymentTypeDescription;
}

export const CreditCardBrandAbbr: any = {
    visa: 'visa',
    masterCard: 'masterCard',
    americanExpress: 'americanExpress',
    discover: 'discover'
};

export const CardType: any = {
    debit: 'debit',
    credit: 'credit',
};

export const BillingPlanCodes: any = {
    full: 'F',
    twice: '2',
    quarterly: '4',
    monthly: 'M',
    payroll: 'P',
    combined: 'C'
};

export const BillingFrequencyDescriptions: any = {
    full: 'Once (full balance)',
    twice: 'Twice (two payments)',
    quarterly: 'Once every 3 months',
    monthly: 'Monthly',
    payroll: 'Payroll deduction',
    combined: 'Differs per policy'
};

export const BillingFrequencyOptions: any = [
    { key: BillingFrequencyDescriptions.full, value: BillingPlanCodes.full },
    { key: BillingFrequencyDescriptions.twice, value: BillingPlanCodes.twice },
    { key: BillingFrequencyDescriptions.quarterly, value: BillingPlanCodes.quarterly },
    { key: BillingFrequencyDescriptions.monthly, value: BillingPlanCodes.monthly }
];

export function GetBillingFrequencyOptions(sixMonthTermFound: boolean) {
    const billingFrequencyOptions: any = [
        { key: BillingFrequencyDescriptions.full, value: BillingPlanCodes.full },
        { key: BillingFrequencyDescriptions.twice, value: BillingPlanCodes.twice },
        { key: BillingFrequencyDescriptions.quarterly, value: BillingPlanCodes.quarterly },
        { key: BillingFrequencyDescriptions.monthly, value: BillingPlanCodes.monthly }
    ];

    if (sixMonthTermFound) {
        const index: number = billingFrequencyOptions.findIndex(x => x.key === BillingFrequencyDescriptions.quarterly);
        if (index > -1) {
            billingFrequencyOptions.splice(index, 1);
        }
    }

    return billingFrequencyOptions;
}

export const CombinedBillingFrequencyOption: any = {
    key: BillingFrequencyDescriptions.combined, value: BillingPlanCodes.combined
};

export const BillingPlanFriendlyDisplayText: any = [
    { key: BillingPlanCodes.full, value: '12 months' },
    { key: BillingPlanCodes.twice, value: '6 months' },
    { key: BillingPlanCodes.quarterly, value: '3 months' },
    { key: BillingPlanCodes.monthly, value: 'month' },
    { key: BillingPlanCodes.payroll, value: 'payroll deduction' }
];

export const EFTErrorCodes: any = {
    invalidRoutingNumber: 40,
    invalidBankAccountNumber: 42
};

export const PolicyTermTypes: any = {
    sixMonthTerm: 6,
    twelveMonthTerm: 12
}

export function setBillingPolicyPlanDescription(billingPlanCode: string) {
    switch (billingPlanCode) {
        case BillingPlanCodes:
            return 'Full';
        case BillingPlanCodes.twice:
            return '2-pay';
        case BillingPlanCodes.quarterly:
            return '4-Pay';
        case BillingPlanCodes.monthly:
            return 'Monthly';
        case BillingPlanCodes.payroll:
            return 'Payroll Ded';
        default:
            return billingPlanCode;
    }
}

export const ErrorFields: any = {
    accountNum: 'ACCOUNTNUM',
    billingPlan: 'BILLINGPLAN',
    monthlyDueDate: 'MONTHLYDUEDATE',
    paymentMethod: 'PAYMENTMETHOD',
    postponeDueDate: 'POSTPONEDUEDATE'
};

export function setCreditCardBrandAbbr(brand: string) {
    switch (brand) {
        case CreditCardBrandAbbr.visa:
            return 'VI';
        case CreditCardBrandAbbr.masterCard:
            return 'MC';
        case CreditCardBrandAbbr.americanExpress:
            return 'AX';
        case CreditCardBrandAbbr.discover:
            return 'DI';
        default:
            return brand;
    }
}

export function setCardTypeAbbr(type: string): string {
    switch (type) {
        case CardType.debit:
            return 'DC';
        default:
            return 'CC';
    }
}

export function isErrorRuleCase(errorRuleCode: string): boolean {
    let isErrorRule: boolean = false;
    const fieldRuleCodes: any = {
        changeWhenAccountIsInWarningStatus: '273193',
        dueDateChangedMoreThanOnceWithin6Months: '271797',
        billingPlanChangedWithPartialPaymentOutstanding: '279112',
        paymentMethodCreditCardRetrievalError: '285350'

        // Error rule codes not returned by BPS but created in CAM
        // noCodeMonthlyDueDate: '11111111',
        // noCodePaymentMethod: '22222222',
        // noCodeAccountNum: '33333333',
        // noCodeBillingPlan: '44444444'
    };

    if (!errorRuleCode) {
        return false;
    }

    for (let prop in fieldRuleCodes) {
        if (errorRuleCode === fieldRuleCodes[prop]) {
            isErrorRule = true;
            break;
        }
    }

    return isErrorRule;
}

export const AccountOptionsNextActions = {
    BILL: '7L',
    BILL_SENT_TO_BANK: '7T'
};