// Digits following characters should indicate the distribution of tests
// Example:
//      T1 vs. C1 would mean 50/50 split
//      T1 vs. T2 vs. C1 would mean 33/33/33 split
export const TestAndControlVariants: { test1: string, test2: string, control1: string } = {
    test1: 'T1',
    test2: 'T2',
    control1: 'C1'
};

export const TrafficSplittingGroups = {
    testGroup1: "TestGroup1",
    testGroup2: "TestGroup2",
    testGroup3: "TestGroup3",
    testGroup4: "TestGroup4"
}

export const ExperimentNames: any = {
    renewalTabView: 'renewal_tab'
}

// Tealium start and completes
export const ConfirmServiceType = {
    START: 'ServiceStart',
    COMPLETE: 'ServiceComplete'
}

// Tealium tool names for flows
export const ToolName = {
    ACCOUNT_RECOVERY: 'Auth0 AccountRecovery',
    ACCOUNT_REGISTRATION: 'Auth0 Registration',
    EMAIL_ACCELERATION: 'Auth0 Email Acceleration',
    CANCEL_SCHEDULED_PAYMENT: 'Cancel Scheduled Payment'
}

export const TealiumViewAnalytics = {
    views: [
        { url: '/account/create?', serviceType: ConfirmServiceType.START, toolName: ToolName.EMAIL_ACCELERATION },
        { url: '/account/createnew', serviceType: ConfirmServiceType.START, toolName: ToolName.ACCOUNT_REGISTRATION },
        { url: '/account/forgotpassword', serviceType: ConfirmServiceType.START, toolName: ToolName.ACCOUNT_RECOVERY },
        { url: '/checkemail?type=create', serviceType: ConfirmServiceType.COMPLETE, toolName: ToolName.ACCOUNT_REGISTRATION },
        { url: '/checkemail?type=email', serviceType: ConfirmServiceType.COMPLETE, toolName: ToolName.EMAIL_ACCELERATION },
        { url: '/checkemail?type=reset', serviceType: ConfirmServiceType.COMPLETE, toolName: ToolName.ACCOUNT_RECOVERY }
    ]
}

export const ContentType = {
    MODAL: "modal",
    ACTION: "action",
    ERROR: "error"
}

export const EsignEventAnalytics = {
    ESIGN_CONFIRM_START: 'eSignStart',
    ESIGN_CONFIRM_COMPLETE: 'eSignComplete',
    ESIGN_SINGLE_SIGNER: 'Single_Signer',
    ESIGN_MULTI_SIGNER: 'Multi_Signer',
    ESIGN_SINGLE_TRANSACTION: 'Single_Transaction',
    ESIGN_MULTI_TRANSACTION: 'Multi_Transaction',
    ESIGN_TITLE_NAME_START: 'eSignPolicies',
    ESIGN_TITLE_NAME_CONFIRMATION: 'eSignConfirmation'
}

export const AnalyticsTransactions = {
    AUTH: 'Auth',
    CPC: 'CPC',
    PAYMENT: 'Payment',
    RPP: 'RPP',
    OPC: 'OPC',
    REQUEST_DOCS: 'RequestDocs',
    VIEW_DOCS: 'ViewDocs'
}

export const AnalyticsApplicationNames = {
    ACCOUNT_ACTIVATION: 'AccountActivation',
    ACCOUNT_RECOVERY: 'AccountRecovery',
    ACCOUNT_REGISTRATION: 'AccountRegistration',
    MAP: 'MAP',
    PWOL: 'PWOL',
    CANCEL_SAP: 'Cancel Scheduled Payment',
    CHANGE_EMAIL: 'Change Email',
    ENROLL_AUTOPAY: 'Enroll Autopay',
    UPDATE_AUTOPAY: 'Update Autopay',
    UPDATE_PAYMENT_SETTINGS: 'Update Payment Settings',
    ADD_DRIVER: 'Add Driver',
    UPDATE_DRIVER: 'Update Driver',
    REMOVE_DRIVER: 'Remove Driver',
    ADD_VEHICLE: 'Add Vehicle',
    REMOVE_VEHICLE: 'Remove Vehicle',
    REPLACE_VEHICLE: 'Replace Vehicle',
    LOAN_LEASE_CHANGE: 'Loan Lease Change',
    UPDATE_ADDRESS: 'Edit Address',
    UPDATE_NAME: 'Change Name',
    EMAIL_TEMP_ID: 'Email Temp ID',
    VIEW_DOCS_BILLING: 'View DC Documents - View Billing',
    VIEW_DOCS_CANCELLATION: 'View DC Documents - Cancellation',
    VIEW_DOCS_ID_CARD: 'View DC Documents - ID Card',
    VIEW_DOCS_POLICY_CHANGE: 'View DC Documents - Policy Change',
    VIEW_DOCS_REINSTATEMENT: 'View DC Documents - Reinstatement',
    VIEW_DOCS_RENEWAL: 'View DC Documents - View Renewal',
    VIEW_DOCS_TEMP_CARD: 'View Temp Card',
    VIEW_DOCS_WELCOME_PACKET: 'View DC Documents - Welcome Packet',
}

export const AccountApplicationDetails = {
    EMAIL_ACCELERATION: 'Email Acceleration'
}

export const PolicyApplicationDetails = {
    ADD_LOAN_PROVIDER: 'Add Loan Provider',
    UPDATE_LOAN_PROVIDER: 'Update Loan Provider',
    REMOVE_LOAN_PROVIDER: 'Remove Loan Provider',
    VEHICLE_FINANCED: 'Financed',
    VEHICLE_LEASED: 'Leased',
    VEHICLE_OWNED: 'Owned',
}

export const AnalyticsEventTypes = {
    START: 'Start',
    VERIFY: 'Verify',
    COMPLETE: 'Complete'
}

export const BillingApplicationDetails = {
    PAY_METHOD_ADD_EFT: 'Paymethod_Add EFT',
    PAY_METHOD_ADD_CC: 'Paymethod_Add CC',
    PAY_METHOD_UPDATE_EFT: 'Paymethod_Update EFT',
    PAY_METHOD_UPDATE_RCC: 'Paymethod_Update RCC',
    TURNOFF_AUTOPAY: 'Turnoff_AutoPay',
    RPP_WITHDRAWAL_DATE: 'Payment Settings_Withdrawal Date',
    RPP_BILL_FREQUENCY: 'Payment Settings_Bill Frequency',
    REGULAR_PAY_WITHDRAWAL_DATE: 'Payment Settings_Direct Bill Withdrawal Date',
    REGULAR_PAY_BILL_FREQUENCY: 'Payment Settings_Direct Bill Frequency',
    POSTPONE_RPP: 'Payment Settings_Postpone Payment'
}

export const ViewDocsApplicationDetails = {
    BILL_STATEMENT_REG_PAY: 'Billing Statement - Regular Pay',
    BILL_STATEMENT_REFT: 'Billing Statement - REFT',
    BILL_STATEMENT_PAYROLL: 'Billing Statement - Payroll Deduction',
    BILL_STATEMENT_RCC: 'Billing Statement - RCC',
    CANCELLATION_NON_PAY: 'Cancellation - Non-pay',
    CANCELLATION_UW: 'Cancellation - UW',
    CANCELLATION_NON_RENEWAL_AGENT: 'Cancellation - Non-renewal Agent',
    CANCELLATION_NON_RENEWAL_UW: 'Cancellation - Non-renewal UW',
}

export const ViewDocsAppNameLookup = {
    'G40': AnalyticsApplicationNames.VIEW_DOCS_BILLING,
    'G42': AnalyticsApplicationNames.VIEW_DOCS_BILLING,
    'G43': AnalyticsApplicationNames.VIEW_DOCS_BILLING,
    'G44': AnalyticsApplicationNames.VIEW_DOCS_BILLING,
    'IA': AnalyticsApplicationNames.VIEW_DOCS_CANCELLATION,
    'PM1': AnalyticsApplicationNames.VIEW_DOCS_WELCOME_PACKET,
    'PM4': AnalyticsApplicationNames.VIEW_DOCS_POLICY_CHANGE,
    'PM5': AnalyticsApplicationNames.VIEW_DOCS_RENEWAL,
    'PM7': AnalyticsApplicationNames.VIEW_DOCS_ID_CARD,
    'PM8': AnalyticsApplicationNames.VIEW_DOCS_CANCELLATION,
    'PM9': AnalyticsApplicationNames.VIEW_DOCS_REINSTATEMENT,
    'T12': AnalyticsApplicationNames.VIEW_DOCS_CANCELLATION,
    'T15': AnalyticsApplicationNames.VIEW_DOCS_CANCELLATION,
    'T16': AnalyticsApplicationNames.VIEW_DOCS_CANCELLATION
}

export const ViewDocsAppDetailsLookup = {
    'G40': ViewDocsApplicationDetails.BILL_STATEMENT_REG_PAY,
    'G42': ViewDocsApplicationDetails.BILL_STATEMENT_REFT,
    'G43': ViewDocsApplicationDetails.BILL_STATEMENT_PAYROLL,
    'G44': ViewDocsApplicationDetails.BILL_STATEMENT_RCC,
    'IA': ViewDocsApplicationDetails.CANCELLATION_NON_PAY,
    'T12': ViewDocsApplicationDetails.CANCELLATION_UW,
    'T15': ViewDocsApplicationDetails.CANCELLATION_NON_RENEWAL_AGENT,
    'T16': ViewDocsApplicationDetails.CANCELLATION_NON_RENEWAL_UW
}
