<div class="eft-input">
  <div class="bank-type-radio-title" *ngIf="!hideTextForMakePayment">What account would you like to use for autopay?</div>
  <div class="bank-type-radio-title" *ngIf="displayForMakePayment">The electronics funds transfer must be from a personal checking account.</div>
  <div class="bank-type-radio-container" *ngIf="!hideTextForMakePayment">
    <div class="bank-type-radio-buttons">
      <div class="bank-type-radio-button" (click)="setEFTAccountType(accountTypeList.checking)" (keyup.enter)="setEFTAccountType(accountTypeList.checking)" tabindex="0"
           role="radio"
           [attr.data-value]="EFTData.accountType === accountTypeList.checking ? 'selected' : 'unselected'"
           [aria-checked]="EFTData.accountType === accountTypeList.checking">
        <input id="checking" type="radio" [checked]="EFTData.accountType === accountTypeList.checking" tabindex="-1">
        <label for="checking">Checking</label>
      </div>
      <div class="bank-type-radio-button" (click)="setEFTAccountType(accountTypeList.savings)" (keyup.enter)="setEFTAccountType(accountTypeList.savings)" tabindex="0"
           role="radio"
           [attr.data-value]="EFTData.accountType === accountTypeList.savings ? 'selected' : 'unselected'"
           [aria-checked]="EFTData.accountType === accountTypeList.savings">
        <input id="savings" type="radio" [checked]="EFTData.accountType === accountTypeList.savings" tabindex="-1">
        <label for="savings">Savings</label>
      </div>
    </div>
  </div>
  <app-input class="eft-name-input sm_cobrowsing_masked_field" [id]="'eft_name_input'" name="Name on bank account" label="Name on account"
             [(fieldValidation)]="nameOnBankValidation" [(value)]="EFTData.nameOnBank" maxLength="60"
             (valueChange)="onFieldChange()"></app-input>
  <div class="eft-numbers-input-container">
    <app-input class="eft-routing-input sm_cobrowsing_masked_field" [id]="'eft_routing_input'" name="Bank routing number" label="Routing number"
               type="number" [(fieldValidation)]="routingNumberValidation" [(value)]="EFTData.routingNumber" maxLength="9"
               (valueChange)="onFieldChange()"></app-input>
    <app-input class="eft-account-number-input sm_cobrowsing_masked_field" [id]="'eft_account_number_input'" name="Bank account number"
               label="Account number" type="text" [(fieldValidation)]="accountNumberValidation"
               [(value)]="EFTData.accountNumber" maxLength="17" (valueChange)="onFieldChange()"></app-input>
  </div>
  <div class="find-account-number">
    <span>Have a check handy? </span>
    <a id="routing_account_number" class="sco-link sco-textLink" [routerLink]="[]" (click)="openRoutingAccountNumber();" role="button">See where to find your routing and account numbers.</a>
  </div>
  <div class="authorization-container" *ngIf="!hideTextForMakePayment">
    <span>By clicking Authorize, I agree to the terms and conditions of the </span>
    <a class="authorization-link" (click)="openEFTAuthorizationModal()" [routerLink]="[]" role="button" name="View automatic bank withdrawal authorization">Automatic bank withdrawal authorization</a>
    <span>, giving Safeco Insurance authority to automatically withdraw payments from my bank account and to return any refunds due in the same manner.</span>
  </div>
  <div class="sco-button-group">
    <button id="eft_cancel" class="sco-button sco--outline" (click)="cancelEFT()" name="Cancel changes" role="button" tabindex="0" data-heap-id="Autopay_btn_eftCancel">Cancel</button>
    <button id="eft_authorize" class="sco-button sco--fill sm_cobrowsing_disabled_field" (click)="authorizeEFT()" name="Authorize automatic bank withdrawals" role="button" tabindex="0" data-heap-id="Autopay_btn_eftAuthorize">Authorize</button>
  </div>
</div>
